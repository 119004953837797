export default (permissions, userRole) => [
  // {
  //   isVisible: permissions.dashboard.canViewAgentDashboard,
  //   link: '/dash360/agent',
  //   title: 'Dashboard'
  // },
  // {
  //   isVisible: permissions.dashboard.canViewManagerDashboard,
  //   link: '/dash360/restaurant-manager-dashboard',
  //   title: 'Dashboard'
  // },
  // {
  //   isVisible: permissions.dashboard.canViewAreaManagerDashboard,
  //   link: '/dash360/admin-L2',
  //   title: 'Dashboard'
  // },
  // {
  //   isVisible: permissions.dashboard.canViewL2Dashboard,
  //   link: '/dash360/L2-dashboard',
  //   title: 'Dashboard'
  // },
  {
    isVisible: permissions.admin.canAccessAdminReports,
    link: '/dailyreport',
    title: 'Autodialer DailyReport'
  },
  // {
  //   isVisible: permissions.admin.canAccessAdminReports,
  //   link: '/report',
  //   title: 'Report'
  // },
  // {
  //   isVisible: permissions.admin.canAccessAdminReports,
  //   link: '/report',
  //   title: 'Report'
  // },
  // {
  //   isVisible: permissions.admin.canAccessAdminUploadPanel,
  //   link: '/upload/home',
  //   title: 'Upload'
  // },
  
  // {
  //   isVisible: permissions.admin.canAccessAdminUploadPanel,
  //   link: '/manageAgents',
  //   title: 'Manage Agents'
  // },

  // {
  //   isVisible: permissions.admin.canAccessAdminUploadPanel,
  //   link: '/recordings/cdr',
  //   title: 'CDR Reports'
  // },
  // {
  //   isVisible: permissions.admin.canAccessAdminUploadPanel,
  //   link: '/zohoreports/cdr',
  //   title: 'ZOHO Reports'
  // },
  // {
  //   isVisible: permissions.admin.canAccessAdminUploadPanel,
  //   link: '/AgentPerformance',
  //   title: 'Agent Performance'
  // },
  // {
  //   isVisible: permissions.admin.canAccessAdminUploadPanel,
  //   link: '/interactionreport',
  //   title: 'Interaction Report'
  // },
  // {
  //   isVisible: permissions.admin.canAccessAdminUploadPanel,
  //   link: '/campaign',
  //   title: 'Campaign Dashboard'
  // },
  {
    isVisible: permissions.admin.canAccessAdminUploadPanel,
    link: '/campaignreport',
    title: 'Autodialer Campaign Report'
  },
  {
    isVisible: permissions.admin.canAccessAdminUploadPanel,
    link: '/manualInteractionReports',
    title: 'Manual Interaction Reports'
  },

  // {
  //   isVisible: permissions.admin.canAccessAdminReports,
  //   link: '/recordings/cdr',
  //   title: 'Voice Recordings'
  // },
  
];
