import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

function createData(key, value) {
  return { key, value };
}

const CustomerDetails1 = ({ customer }) => {
  
  let rows = [];
  if (customer !== null) {
    rows = [
      createData('Customer Name', customer.name),
      createData('Customer Number', customer.phone),
      createData('Product', customer.product),
      createData('Channel', customer.channel),
      createData('Product link', customer.productlink),
      createData('Email ID', customer.email)
    ];
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="customer table">
          <TableBody>
            {rows.length !== 0 &&
              rows.map(row => (
                <TableRow key={row.key}>
                  <TableCell component="th" scope="row">
                    {row.key}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomerDetails1;