import { Card, CardContent, Grid, Button, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import DispositionForm from 'src/modules/dashboard-360/components/DispositionFormManualCall';
import packageConfig from '../../../../../../package.json';

const option = [
  {
    label: 'Busy'
  },
  {
    label: 'RNR'
  },
  {
    label: 'Call Barred'
  },
  {
    label: 'Call Disconnected'
  },
  {
    label: 'Switch Off'
  },
  {
    label: 'Talk Later'
  },

  {
    label: 'Phone number not in use'
  },

  {
    label: 'Out of Network coverage area'
  },
  {
    label: 'ID Block'
  },

  {
    label: 'Onboarding Issues'
  },

  {
    label: 'Documents Updating issues/KYC Pending'
  },
  {
    label: 'Zone Change Required / Location Issue'
  },

  {
    label: 'Rate Card issue'
  },

  {
    label: 'App Issue/ Tech Issue/ No intimation of order read'
  },

  {
    label: 'Login Issue'
  },

  {
    label:
      'Pay-out issue/ Incentives Not Received/ Incentives Received Less/Old pay-out not received / Fuel Charges not received'
  },

  {
    label: 'Pick Up Issue (Long distance ) / Long distance less pay-out'
  },

  {
    label: 'Delayed Order in Restaurant'
  },

  {
    label: 'Order Flow Less'
  },

  {
    label: 'TL/FE Not Responding'
  },
  {
    label: 'Shift time issue'
  },

  {
    label: 'Wrong Zone tagged'
  },
  {
    label: 'Joining Fees Demanded from the HUB Manager'
  }
];

export default function ManualCall() {
  const [callFunctionObject, setCallFunctionObject] = React.useState({
    agentPhoneNumber: '',
    toBeCalledPhoneNumber: ''
  });
  const [showDisposition, setShowDisposition] = React.useState(false);
  const [showWrapUpButton, setShowWrapUpButton] = React.useState(false);
  const [showCallerButtton, setShowCallerButtton] = React.useState(false);
  const [
    showCallerDispositionButton,
    setShowCallerDispositionButton
  ] = React.useState(true);

  const [record, setRecord] = React.useState(null);
  const [dispositionForm, setdispositionForm] = React.useState({
    Grievance: ''
  });
  const handleChangeDispositionForm = e => {
    const { name, value } = e.target;
    setdispositionForm(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (value !== '') {
      setShowCallerDispositionButton(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('callOriginated') === 'true') {
      setShowCallerButtton(true);
      setShowDisposition(true);
    } else if (localStorage.getItem('callOriginated') === 'false') {
      setShowCallerButtton(false);
      setShowDisposition(false);
    }
    if (localStorage.getItem('wrapUpTime') === 'true') {
      setShowWrapUpButton(true);
    }
  }, []);
  const handleChangeCallFunctionObject = e => {
    const { name, value } = e.target;
    setCallFunctionObject(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const callOriginateFunction = () => {
    let today = new Date().toJSON();
    let date = Date.parse(today) + '';
    let accountCode = 'LM' + date.slice(3);
    localStorage.setItem('AccountCode', accountCode);
    if (
      // callFunctionObject.agentPhoneNumber === '' ||
      callFunctionObject.toBeCalledPhoneNumber === ''
    ) {
      alert('Enter the caller number required');
    } else {
      fetch(
        `${packageConfig.proxy
        }/api/actions/originatecallmanual?sipAgentID=${localStorage
          .getItem('AgentSIPID1')
          .slice(7, 17)}&NumbertobeCalled=${callFunctionObject.toBeCalledPhoneNumber
        }&Account=${accountCode}&ActionID=12345&agentId=${localStorage
          .getItem('Agent_Object_ID')
          .trim()}
        &agentName=${localStorage.getItem('AgentName')}`
      )
        .then(res => res.json())
        .then(
          response => {
            setShowDisposition(true);
            // alert(response);
            setShowCallerButtton(true);
            localStorage.setItem('callOriginated', 'true');
            localStorage.setItem('wrapUpTime', 'true');
            setShowWrapUpButton(true);
          },
          error => {
            alert('Error', error);
          }
        );
      // alert('Call Originated');
      // setInterval(() => {
      //   setShowCallerButtton(false);
      // }, 1000 * 15);
    }
  };

  const handleWrapUpTime = () => {
    fetch(
      `${packageConfig.proxy
      }/api/crm-route/manualInteractionUpdatesWrapUptime?AccountCode=${localStorage.getItem(
        'AccountCode'
      )}`
    )
      .then(res => res.json())
      .then(
        response => {
          // alert(JSON.stringify(response))
          localStorage.setItem('wrapUpTime', 'false');
          setShowWrapUpButton(false);
        },
        error => {
          alert(error);
        }
      );
  };
  return (
    <Grid container spacing={3}>
      {showWrapUpButton ? (
        <Grid item xs={12} md={12} lg={12} style={{ padding: '10px 0px', display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            variant="contained"
            style={{ display: 'flex', width: '', marginTop: 15 }}
            onClick={handleWrapUpTime}
          >
            Wrap Up Time
          </Button>
        </Grid>
      ): (
        <Grid
        item
        xs={12}
        md={6}
        lg={6}
        style={{ display: 'flex', justifyContent: 'center' }}
      >


        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              display: 'flex',
              maxHeight: 100,
              justifyContent: 'center'
            }}
          >
            <Card>
              <CardContent>
                <>
                  <label>
                    <input
                      style={{ padding: 10, margin: 10 }}
                      type="number"
                      required={true}
                      placeholder="Enter Agent PhoneNumber"
                      name="agentPhoneNumber"
                      value={localStorage.getItem('AgentSIPID1').slice(7, 17)}
                      onChange={handleChangeCallFunctionObject}
                      disabled
                    />
                  </label>
                  <label>
                    <input
                      style={{ padding: 10, margin: 10 }}
                      type="number"
                      required={true}
                      placeholder="Enter Number to be called"
                      name="toBeCalledPhoneNumber"
                      value={callFunctionObject.toBeCalledPhoneNumber}
                      onChange={handleChangeCallFunctionObject}
                    />
                  </label>
                  <Button
                    onClick={callOriginateFunction}
                    style={{ padding: 10, margin: 10 }}
                    variant="outlined"
                    type="submit"
                    disabled={showCallerButtton}
                  >
                    Call
                  </Button>
                </>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      )}



      {showDisposition && (
        <Grid item xs={12} md={6} lg={6} style={{ padding: '10px 50px' }}>
          <Grid container>

            <Grid item xs={12} md={12} lg={12}>
              <DispositionForm setdispositionForm={setdispositionForm} setCallFunctionObject={setCallFunctionObject} setShowWrapUpButton={setShowWrapUpButton} setShowCallerButtton={setShowCallerButtton} setShowDisposition={setShowDisposition} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
