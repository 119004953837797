export const GET_DEALER_PROFILE = '/bo/boapi/profileID';
export const GET_DEALER_JOINING_DETAILS = '/bo/boapi/distjoining';
export const GET_DEALER_ORDERS = '/bo/boapi/orders';
export const GET_DEALER_SINGLE_ORDERS = '/bo/boapi/order';
export const GET_DEALER_INVOICES = '/bo/boapi/invoice';
export const GET_DEALER_KYC = '/bo/boapi/kycstatus';
export const PUT_BREAK_AGENT = '/agentservice/crm/agentbreakservices';
export const GET_INTERACTION_BY_DISTRIBUTOR_ID =
  '/agentservice/crm/interactions/getByDistributerID?distributerID=';
export const GET_INTERACTION_BY_AGENT_SIP_ID =
  '/agentservice/crm/interactions/getByAgentSIPID?SipID=';
export const UPDATE_CURRENT_STATUS = '/agentservice/crm/currentstatuses/';
export const GET_CURRENT_STATUS_BY_AGENT_SIP_ID =
  '/agentservice/crm/currentstatuses/agentSipID?agentSipID=';
export const ORIGINATE_CALL_WITH_SIP_ID =
  '/agentservice/ami/actions/orginatecall?';
export const UPDATE_CALL_STATUS = '/agentservice/crm/interactions/';
export const GET_INBOUND_DASHBOARD_DATA =
  '/gpreport/service/dashboardcount?AccessKeys=123';
const prefix = process.env.NODE_ENV === 'production' ? '/' : '/';
export const SAVE_DISPOSITION = '/crm-route/adddisposition';
export const SAVE_DISPOSITION_Manual = 'https://loadshare.granalytics.in/api/crm-route/manualInteractionUpdates';
// export const SAVE_DISPOSITION_Manual = 'http://0.0.0.0:7014/crm-route/manualInteractionUpdates';






//Statging api url's
// export const LOGIN ='http://192.168.4.44:43001/user/login'
// export const LOGOUT = 'http://192.168.4.44:43001/user/logout'
// export const AGENT_SERVICE = 'http://192.168.4.44:62001/api'
// export const AMI = 'http://192.168.4.44:62002/ami'
// export const CAMPAIGN_CREATE = 'http://192.168.3.36:62008/api'
// export const CAMPAIGN = 'http://192.168.4.44:62010'
// export const ADD_AGENT ='http://192.168.4.44:62003/api'



//production api url's
// export const LOGIN ='/user/login'
// export const LOGOUT = '/user/logout'
export const AGENT_SERVICE = '/autodialeragentservice/api'
export const AMI = ''
export const CAMPAIGN_CREATE = '/autodialerfileupload/api'
export const CAMPAIGN = '/autodialerfileupload'
export const ADD_AGENT ='/createagent/api'


// import {ADD_AGENT} from 'src/modules/dashboard-360/utils/endpoints'
