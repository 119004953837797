import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  AppBar
} from '@material-ui/core';
import DownloadReport from '../DownloadReport';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import packageConfig from '../../../package.json';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Axios from 'axios';

import {DataGridPremium,GridToolbarContainer,GridToolbarExport,GridColumns,GridRowsProp,} from '@mui/x-data-grid-premium';

import ExcelDownload from '../ReportExcelDownload';
// DownloadReport

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));
const InteractionReport = props => {
  const classes = useStyles();
  //   const [campaigns, setCampaigns] = useState([]);
  //   const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [agentStausReports, setAgentStausReports] = useState([]);
  const [interactionReport, setInteractionReport] = useState([]);
  const [agentsArray, setAgentsArray] = useState([]);

  const [startDate, setstartDate] = useState('');
  const [endDate, setendDate] = useState('');

  const [reportType, setReportType] = useState('click_to_call');


  const [showTable, setShowTable] = useState(false);
  const [showLoading, setShowLoading] = useState(false);


  const [agentId, setagentId] = React.useState('');
  const [customerPhoneNo, setCustomerPhoneNo] = React.useState('');

  const [apiBody, setApiBody] = useState({
    checkForAgent: false,
    checkDate: true,
    checkCustomerNo: false
  });
  const handleChangeChecked = event => {
    // if(!apiBody.checkDate && !apiBody.checkForAgent){
    //   alert('Keep one filter on!');
    // }else{
    setApiBody({ ...apiBody, [event.target.name]: event.target.checked });

    // }
  };

  function CallReport() {
      setShowLoading(true);
    fetch(
      `${packageConfig.proxyapi}/autodialerfileupload/channel/manualInteractionFilterService`,
      // `http://localhost:6014/autodialerfileupload/channel/manualInteractionFilterService`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          agentId: agentId,
          checkForAgent: apiBody.checkForAgent,
          checkDate: apiBody.checkDate,
          startDate: !apiBody.checkDate ? new Date():startDate,
          endDate: !apiBody.checkDate ? new Date(): endDate,
          checkCustomerNo: apiBody.checkCustomerNo,
          customerNo: customerPhoneNo,
          agent_role: localStorage.getItem('permissions') === 'canViewAreaManagerDashboard' ? 'agent_L2' : 'agent_L1',
          // report_name_type: "auto_dialer"
          report_name_type: reportType
          // auto_dialer
        })
      }
    )
      .then(res => res.json())
      .then(
        async response => {
          console.log(response);
          let reportArray = [];
          if (response.reportInteractionArray.length > 0) {
            let i = 0;
            response.reportInteractionArray.map(ele => {
              console.log(ele);
              i = i + 1;
              ele.id = i;
              reportArray.push(ele);
            });
          }

          setAgentStausReports(reportArray);
          setShowLoading(false);
          setShowTable(true);

        },
        error => {
          alert(error);
        }
      );
    // .then(error)
  }
  useEffect(() => {
    // setDate(new Date())
    // fetch()
    let typeAgent 
    if(localStorage.getItem('permissions') === 'canViewAreaManagerDashboard'){
      typeAgent = 'agent_L2'
    }else{
      if(localStorage.getItem('agent_role') === 'admin_L2'){
        typeAgent = 'agent_L2'
      }else{
        typeAgent = 'agent_L1'
      }
    }
    (async () => {
      const res = await Axios.get(`/autodialeragentservice/api/agents?agent_role=${typeAgent}`);
      // const res = await Axios.get(`/autodialeragentservice/api/agents?agent_role=${typeAgent}`);
      setAgentsArray(res.data);
    })();
  }, []);

// ==================
// ==================
const agentStatusColumns = [
  {headerName: 'CallDate',field: 'date',flex: 2.0},
  {headerName: 'Source',field: 'Source',flex: 1.0},
  {headerName: 'Call Type',field: 'callType',flex: 1.0},
  {headerName: 'Agent Name',field: 'agentName',flex: 1.0},
  {headerName: 'Duration',field: 'Duration',flex: 1.0},
  {headerName: 'Main Disposition',field: 'mainDisposition',flex: 1.0},
  {headerName: 'AgentRemarks',field: 'agentRemarks',flex: 1.0},
  {headerName: 'Campiagn',field: 'Campiagn',flex: 1.0},
  {headerName: 'Sub Cateogry',field: 'SubCateogry',flex: 1.0},
  {headerName: 'Destination',field: 'Destination',flex: 1.0},
  {headerName: 'Attempt',field: 'attempt',flex: 1.0},
  {headerName: 'Total Count',field: 'totalcount',flex: 1.0},
  
];
// ==================
// ==================

  const agentStatusColumns_old = [
    {
      headerName: 'Call Type',
      field: 'callType',
      flex: 1.0
    },
    {
      headerName: 'Agent Name',
      field: 'agentName',
      flex: 1.0
    },
    {
      headerName: 'Phone Number',
      field: 'Source',
      flex: 1.0
    },
    // {
    //   headerName: 'Customer Phone Number',
    //   field: 'Destination',
    //   flex: 1.0
    // },
    {
      headerName: 'Call Duration',
      field: 'Duration',
      flex: 1.0
    },
    {
      headerName: 'Main Disposition',
      field: 'mainDisposition',
      flex: 1.0
    },
    {
      headerName: 'Agent Remarks',
      field: 'agentRemarks',
      flex: 1.0
    },
    {
      headerName: 'Campiagn',
      field: 'Campiagn',
      flex: 1.0
    },
    {
      headerName: 'SubCateogry',
      field: 'SubCateogry',
      flex: 1.0
    },
    {
      headerName: 'Campaign Count',
      field: 'Campaign Count',
      flex: 1.0
    },
    {
      headerName: 'Total Count',
      field: 'Total Count',
      flex: 1.0
    },
    {
      headerName: 'Call Date',
      field: 'createdAt',
      flex: 1.0,
      renderCell: data => (

        <>
        {/* {data.row.date.slice(0, 10)} */}
        
        {/* {()=>{
          let passdate = new Date();
          passdate.setHours(passdate.getHours() + 5);
          passdate.setMinutes(passdate.getMinutes() + 30);
          return passdate;
        }} */}
        {/* {data.row.date} */}
        {data.row.date.slice(0, 10)}
        {' '}
        {data.row.date.slice(11,19)}
        </>
      )
    },

  ];

  const handleFormSubmitDateWiseAgent = e => {
    e.preventDefault();
    CallReport();
  };

  return (
   <>
   <div>
     <Grid container spacing={3} direction="row">
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          style={{
            // display: 'flex',
            // justifyContent: 'left',
            padding: '50px 40px',
            paddingBottom: 0
          }}>
          <select value={reportType} onChange={(e) => setReportType(e.target.value)} style={{padding: 10}}>
            <option value="click_to_call">Click To Call</option>
            <option value="auto_dialer">Auto Dialer</option>
            <option value="inbound_call">Inbound Call</option>
          </select>
       </Grid>
       <Grid item
          xs={12}
          sm={12}
          lg={12}
          style={{
            display: 'flex',
            justifyContent: 'left',
            padding: '20px 30px'
          }}>
          {reportType === "click_to_call" && (
               <div style={{ marginTop: 40 }}>
               <Grid container spacing={3} direction="row">
                 <Grid
                   item
                   xs={12}
                   sm={12}
                   lg={12}
                   style={{
                     display: 'flex',
                     justifyContent: 'left',
                     padding: '20px 30px'
                   }}
                 >
                   <div>
                     <Typography variant='h5'>Filter</Typography>
                     <label>
                       Enable Agent Filter
                       <Switch
                         checked={apiBody.checkForAgent}
                         onChange={handleChangeChecked}
                         color="primary"
                         name="checkForAgent"
                         inputProps={{ 'aria-label': 'primary checkbox' }}
                       />
                     </label>
                     <label>
                       Enable Date Filter
                       <Switch
                         checked={apiBody.checkDate}
                         onChange={handleChangeChecked}
                         color="primary"
                         name="checkDate"
                         inputProps={{ 'aria-label': 'primary checkbox' }}
                       />
                     </label>
                     <label>
                       Enable Customer Number
                       <Switch
                         checked={apiBody.checkCustomerNo}
                         onChange={handleChangeChecked}
                         color="primary"
                         name="checkCustomerNo"
                         inputProps={{ 'aria-label': 'primary checkbox' }}
                       />
                     </label>
                   </div>
                 </Grid>
                 
                 <Grid
                   item
                   xs={12}
                   sm={12}
                   lg={12}
                   style={{
                     display: 'flex',
                     justifyContent: 'left',
                     padding: '20px 30px'
                   }}
                 >
         
                   <form onSubmit={handleFormSubmitDateWiseAgent}>
                     {apiBody.checkForAgent && (
                       <label style={{ fontSize: 22 }}>
                         Select Agent{' '}
                         <select style={{ padding: 10 }} name="agentId" value={agentId} onChange={(e) => setagentId(e.target.value)}>
                           <option value='' defaultChecked disabled >Select Agent</option>
                           {agentsArray.map(data => (
                             <option value={data._id}>{data.name}</option>
                           ))}
                         </select>
                       </label>
                     )}
                     {apiBody.checkDate && (
                       <>
                         <label style={{ fontSize: 22 }}>
                           Select Start Date{' '}
                           <input
                             required
                             name="startDate"
                             type="date"
                             value={startDate}
                             onChange={(e) => setstartDate(e.target.value)}
                             style={{ padding: 5 }}
                           />
                         </label>
         
                         <label style={{ fontSize: 22 }}>
                           Select End Date{' '}
                           <input
                             required
                             name="endDate"
                             type="date"
                             value={endDate}
                             onChange={(e) => setendDate(e.target.value)}
                             style={{ padding: 5 }}
                           />
                         </label>
                       </>
                     )}
          {apiBody.checkCustomerNo && (
                       <label style={{ fontSize: 22 }}>
                         Enter Phone number
                         <input required type="number" value={customerPhoneNo} placeholder="Enter Phone" style={{padding: 5}} onChange={(e) => setCustomerPhoneNo(e.target.value)}/>
                       </label>
                     )}
                     <input
                       type="submit"
                       title="Submit"
                       style={{ padding: '10px 15px', margin: 5, cursor: 'pointer' }}
                     />
                   </form>
                   
                   {/* <ExcelDownload data={agentStausReports} /> */}
                   
                 </Grid>
                 {showTable ? (
                   <Grid
                     item
                     xs={12}
                     sm={12}
                     lg={12}
                     style={{
                       display: 'flex',
                       justifyContent: 'left',
                       padding: '5px 30px'
                     }}
                   >
                     {/* <DataGagentStatusColumnsrid
                       rows={agentStausReports}
                       columns={agentStatusColumns}
                       components={{
                         Toolbar: GridToolbar
                       }}
                       pageSize={10}
                       autoHeight="true"
                       pagination
                     /> */}
                     <DataGridPremium
                      rows={agentStausReports}
                      columns={agentStatusColumns}
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      pageSize={10}
                      autoHeight="true"
                      pagination
                    />

                   </Grid>
                 ): (
                   <Grid
                   item
                   xs={12}
                   sm={12}
                   lg={12}
                   style={{
                     display: 'flex',
                     justifyContent: 'center',
                     padding: '5px 30px'
                   }}>
                   {showLoading && (<Typography variant='h4' align='center'>
                 <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                 </Typography>)}
                   
                   </Grid>
                 )}
               </Grid>
             </div>
          )}

          {(reportType ==="auto_dialer" || reportType === "inbound_call") && (
            <>
              <Typography variant='h4' align='center'>
                NOT enabled
              </Typography>
            </>
          )}
     </Grid>
     </Grid>
   </div>

   </>
  );
};

export default InteractionReport;