import AdminDashboard from '../views/admin/admin-dashboard';
import manageagents from '../../../components/Auto-report/manageagents';
import liveagents from '../../../components/Auto-report/liveagents';

export default [
  // {
  //   path: '/report',
  //   exact: false,
  //   crumb: 'Report',
  //   key: 'report',
  //   component: Report,
  //   selector: 'admin.canAccessAdminReports'
  // }
  {
    path: '/liveAgents',
    // routes: adminRoutes,
    exact: false,
    key: 'admin',
    component: liveagents,
    crumb: 'Admin',
    selector: 'admin.canAccessAdminReports'
  },  
  {
    path: '/manageAgents',
    // routes: adminRoutes,
    exact: false,
    key: 'admin',
    component: manageagents,
    crumb: 'Admin',
    selector: 'admin.canAccessAdminReports'
},
];
