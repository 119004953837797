import React, { useEffect, useState } from 'react';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import { Card, CardActionArea, CardContent } from '@material-ui/core';
import { Grid, Typography, Tooltip, IconButton } from '@material-ui/core';
import Axios from 'axios';
//
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment';
//
import Switch from '@material-ui/core/Switch';
import packageConfig from '../../../package.json';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

//
export default function RecordingsCDR() {
  const classes = useStyles();
  //
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  var todayDate = new Date().toJSON().slice(0, 10);

  const [agentsArray, setAgentsArray] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [agentId, setAgentId] = useState('');
  const [customerNumber, setCustomerNumber] = useState('');


  const [statuOfData, setStatuOfData] = useState({
    agentAndDateRange: false
  });
  //
  const [openAlert, setOpenAlert] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [alertColor, setAlertColor] = useState('info');
  const [modalMsg, setModalMsg] = useState('');
  var vertical = 'top';
  var horizontal = 'center';

  //
  var url =
    `${packageConfig.proxyapi}/autodialeragentservice/api/manualInteractionVoiceRecordings`

  var urlLocal =
    'http://localhost:6014/autodialeragentservice/api/manualInteractionVoiceRecordings';
  const showProfile = data => {
    console.log(data.row);
  };

  useEffect(() => {
    let typeAgent 
    if(localStorage.getItem('permissions') === 'canViewAreaManagerDashboard'){
      typeAgent = 'agent_L2'
    }else{
      typeAgent = 'agent_L1'
    }
    (async () => {
      const res = await Axios.get(`/autodialeragentservice/api/agents?agent_role=${typeAgent}`);
      setAgentsArray(res.data);
    })();
  }, []);
  function clickFetchRecs(e) {
    e.preventDefault();

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        agentId: agentId,
        startDate: startDate === "" ? new Date(): startDate,
        endDate: endDate === "" ? new Date(): endDate,
        customerNumber: customerNumber,
        enableCustomerNumber: statuOfData.agentAndDateRange
      })
    })
      .then(res => res.json())
      .then(
        async result => {
          if (result.data.length > 0) {
            let i = 0;
            result.data.map(ele => {
              i = i + 1;
              return (ele.id = i);
            });
            // result.data.map((ele)=>{
            //   return
            // })
          }
          console.log(result.data);
          await setItems(result.data);
          setOpenAlert(true);
          setAlertSeverity('success');
          setAlertColor('success');
          setModalMsg('Success');
        },
        error => {
          setOpenAlert(true);
          setAlertSeverity('error');
          setAlertColor('error');
          setModalMsg('Error');
        }
      );
  }
  function spliceString(str) {
    var spliceStr = str.slice(13);
    return spliceStr;
  }

  function onClickDownload(path) {
    window.location.href = `http://14.97.89.206/${path}`;
  }

  const profilesColumns = [
    {
      headerName: 'Agent Name',
      field: 'agentName',
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      headerName: 'Agent Phone Number',
      field: 'agentPhoneNumber',
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      headerName: 'Customer Number',
      field: 'Destination',
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      headerName: 'Date of Call',
      field: 'createdAt',
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      headerName: 'Action',
      field: 'recordingUrl',

      renderCell: rowData => (
        <>
          <a
            href="javascript:void(0)"
            onClick={() =>
              onClickDownload(spliceString(rowData.row.recordingUrl))
            }
            download
          >
            Recording{' '}
          </a>
        </>
      ),
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    }
  ];
  const handleChangeChecked = event => {
    // if(!apiBody.checkDate && !apiBody.checkForAgent){
    //   alert('Keep one filter on!');
    // }else{
    setStatuOfData({ ...statuOfData, [event.target.name]: event.target.checked });

    // }
  };
  return (
    <>
      <div style={{margin: 15}}>
      Enable Customer Mobile Search Filter
      <Switch
        checked={statuOfData.agentAndDateRange}
        onChange={handleChangeChecked}
        color="primary"
        name="agentAndDateRange"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
      </div>
      {!statuOfData.agentAndDateRange ? (
          <form
          onSubmit={e => clickFetchRecs(e)}
          style={{
            width: '100%',
            border: '1px dotted black',
            padding: 30,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center'
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Select Agent</label>
            <select
              required
              style={{ padding: 10 }}
              name="agentId"
              value={agentId}
              onChange={e => setAgentId(e.target.value)}
            >
              <option value="" defaultChecked disabled>
                Select Agent
              </option>
              {agentsArray.map(data => (
                <option value={data._id}>{data.name}</option>
              ))}
            </select>
            <br />
            {/* {phoneNo} */}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: 10
              }}
            >
              <label>Start Date</label>
  
              <input
                style={{
                  minWidth: 200,
                  maxWidth: 200,
                  minHeight: 35,
                  maxHeight: 50,
                  border: '2px solid black'
                }}
                type="date"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
                required
              />
              <br />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>End Date</label>
              <input
                style={{
                  minWidth: 200,
                  maxWidth: 200,
                  minHeight: 35,
                  maxHeight: 50,
                  border: '2px solid black'
                }}
                type="date"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
                required
              />
              <br />
              {/* {date} */}
            </div>
          </div>
  
          <input
            style={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 35,
              maxHeight: 50,
  
              border: '2px dashed black',
              backgroundColor: '#8bc34a',
              cursor: 'pointer'
            }}
            type="submit"
          />
        </form>
      ): (
        <form
        onSubmit={e => clickFetchRecs(e)}
        style={{
          width: '100%',
          border: '1px dotted black',
          padding: 30,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }}
      >
       <div>
       <input placeholder='Customer Mobile Number' 
       required
        value={customerNumber}
        onChange={e => setCustomerNumber(e.target.value)} 
        style={{
          minWidth: 200,
          maxWidth: 200,
          minHeight: 35,
          maxHeight: 50,
          border: '2px solid black',
          padding: 5
        }}
          />
       </div>

        <input
          style={{
            minWidth: 200,
            maxWidth: 200,
            minHeight: 35,
            maxHeight: 50,

            border: '2px dashed black',
            backgroundColor: '#8bc34a',
            cursor: 'pointer'
          }}
          type="submit"
        />
      </form>
      )}
    {items.length > 0 && (
          <Typography variant='h4' style={{padding: 30}}>
          Total Count: {items.length} 
        </Typography>
    )}


      <Grid container spacing="3">
        <Grid item xs={12} md={12} lg={12}>
          {/*  */}
          <Card>
            <CardContent>
              <DataGrid
                rows={items}
                columns={profilesColumns}
                pageSize={20}
                // rowsPerPageOptions={[10, 20, 50]}

                autoHeight="true"
                pagination
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={openAlert}
        autoHideDuration={2000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert
          onClose={() => setOpenAlert(false)}
          severity={alertSeverity}
          color={alertColor}
        >
          {modalMsg}
        </Alert>
      </Snackbar>
    </>
  );
}
