import { Card, CardContent, Grid, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import DispositionForm from './DispositionFormL2';
import packageConfig from '../../../../../../package.json';


export default function ManualCall() {
  const [callFunctionObject, setCallFunctionObject] = React.useState({
    agentPhoneNumber: '',
    toBeCalledPhoneNumber: ''
  });
  const [showDisposition, setShowDisposition] = React.useState(false);
  const [showWrapUpButton, setShowWrapUpButton] = React.useState(false);
  const [showCallerButtton, setShowCallerButtton] = React.useState(false);
  const [
    showCallerDispositionButton,
    setShowCallerDispositionButton
  ] = React.useState(true);
  const [agentStatusData, setAgentStatusData] = useState([]);

  const [record, setRecord] = React.useState(null);
  const [dispositionForm, setdispositionForm] = React.useState({
    Grievance: ''
  });
  const handleChangeDispositionForm = e => {
    const { name, value } = e.target;
    setdispositionForm(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (value !== '') {
      setShowCallerDispositionButton(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem('callOriginated') === 'true') {
      setShowCallerButtton(true);
      setShowDisposition(true);
    } else if (localStorage.getItem('callOriginated') === 'false') {
      setShowCallerButtton(false);
      setShowDisposition(false);
    }
    if (localStorage.getItem('wrapUpTime') === 'true') {
      setShowWrapUpButton(true);
    }
  }, []);
  const handleChangeCallFunctionObject = e => {
    const { name, value } = e.target;
    setCallFunctionObject(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const callOriginateFunction = () => {
    let today = new Date().toJSON();
    let date = Date.parse(today) + '';
    let accountCode = 'LM' + date.slice(3);
    localStorage.setItem('AccountCode', accountCode);
    if (
      // callFunctionObject.agentPhoneNumber === '' ||
      callFunctionObject.toBeCalledPhoneNumber === ''
    ) {
      alert('Enter the caller number required');
    } else {
      fetch(
        `${packageConfig.proxy
        }/api/actions/originatecallmanual?sipAgentID=${localStorage
          .getItem('AgentSIPID1')
          .slice(7, 17)}&NumbertobeCalled=${callFunctionObject.toBeCalledPhoneNumber
        }&Account=${accountCode}&ActionID=12345&agentId=${localStorage
          .getItem('Agent_Object_ID')
          .trim()}
        &agentName=${localStorage.getItem('AgentName')}`
      )
        .then(res => res.json())
        .then(
          response => {
            setShowDisposition(true);
            // alert(response);
            setShowCallerButtton(true);
            localStorage.setItem('callOriginated', 'true');
            localStorage.setItem('wrapUpTime', 'true');
            setShowWrapUpButton(true);
          },
          error => {
            alert('Error', error);
          }
        );
      // alert('Call Originated');
      // setInterval(() => {
      //   setShowCallerButtton(false);
      // }, 1000 * 15);
    }
  };

  const handleWrapUpTime = () => {
    fetch(
      `${packageConfig.proxyapi
      }/crm-route/manualInteractionUpdatesWrapUptime?AccountCode=${localStorage.getItem(
        'AccountCode'
      )}`
    )
      .then(res => res.json())
      .then(
        response => {
          // alert(JSON.stringify(response))
          localStorage.setItem('wrapUpTime', 'false');
          setShowWrapUpButton(false);
        },
        error => {
          alert(error);
        }
      );
  };

  function handleGetAgentReportStatus() {
    fetch(
      `${packageConfig.proxyapi}/autodialeragentservice/api/agentPreformanceStatus`,
      // `http://localhost:6014/autodialeragentservice/api/agentPreformanceStatus`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          agentId: localStorage.getItem('Agent_Object_ID')
        })
      }
    )
      .then(res => res.json())
      .then(response => {
        setAgentStatusData(response.data);
        console.log(response, "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
      });
  }
  //console.log(currentCall,"current caall")
  useEffect(() => {
    handleGetAgentReportStatus();
  }, []);
  return (
    <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12} style={{ padding: '0px 0px', display: "flex", justifyContent: "center" }}>

          {/* <AgentStatusDataFunction agentStatusData={agentStatusData}/> */}
          </Grid>

      {showWrapUpButton ? (
        <Grid item xs={12} md={12} lg={12} style={{ padding: '10px 0px', display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            variant="contained"
            style={{ display: 'flex', width: '', marginTop: 15 }}
            onClick={handleWrapUpTime}
          >
            Wrap Up Time
          </Button>
        </Grid>
      ): (
        <Grid
        item
        xs={12}
        md={6}
        lg={6}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            style={{
              display: 'flex',
              maxHeight: 100,
              justifyContent: 'center'
            }}
          >
            <Card>
              <CardContent>
                <>
                  <label>
                    <input
                      style={{ padding: 10, margin: 10 }}
                      type="number"
                      required={true}
                      placeholder="Enter Agent PhoneNumber"
                      name="agentPhoneNumber"
                      value={localStorage.getItem('AgentSIPID1').slice(7, 17)}
                      onChange={handleChangeCallFunctionObject}
                      disabled
                    />
                  </label>
                  <label>
                    <input
                      style={{ padding: 10, margin: 10 }}
                      type="number"
                      required={true}
                      placeholder="Enter Number to be called"
                      name="toBeCalledPhoneNumber"
                      value={callFunctionObject.toBeCalledPhoneNumber}
                      onChange={handleChangeCallFunctionObject}
                    />
                  </label>
                  <Button
                    onClick={callOriginateFunction}
                    style={{ padding: 10, margin: 10 }}
                    variant="outlined"
                    type="submit"
                    disabled={showCallerButtton}
                  >
                    Call
                  </Button>
                </>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      )}



      {showDisposition && (
        <Grid item xs={12} md={6} lg={6} style={{ padding: '10px 50px' }}>
          <Grid container>

            <Grid item xs={12} md={12} lg={12}>
              <DispositionForm setdispositionForm={setdispositionForm} setCallFunctionObject={setCallFunctionObject} setShowWrapUpButton={setShowWrapUpButton} setShowCallerButtton={setShowCallerButtton} setShowDisposition={setShowDisposition} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}


function AgentStatusDataFunction(props){

  return(
    <div>
    <Grid container>
      <Grid item xs={12} md={12} lg={12} style={{ padding: 10}}>
        <Grid container spacing={3} >
        {Object.keys(props.agentStatusData).map(data => (
          <Grid item xs={12} md={3} lg={2} style={{ padding: 10, textAlign: "center"}}>
            <Card style={{backgroundColor: "#3f51b5a8",padding: "25px 0px", color: "#fff", fontWeight: "bold"}}>
            {data}: {props.agentStatusData[data]}
            </Card>
          </Grid>
        ))}
        </Grid>
      </Grid>
    </Grid>
  </div>
  )
}