import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles,
  ListItem,
  ListItemText,
  Typography,
  Link,
  Menu
} from '@material-ui/core';
import navBarRoutes from 'src/utils/navBarRoutes';
import Logo from 'src/modules/dashboard-360/components/Logo';
import navBarRoutesAdmin from 'src/utils/navBarRoutesAdmin';

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

const useStyles = makeStyles(() => ({
  root: {
    // backgroundColor: '#f6fbff',
    // color:'#3f51b5',
    boxShadow: '0 8px 6px -6px #999',
    textAlign: 'center'
  },
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({
  onMobileClose,
  openMobile,
  permissions,
  userRole,
  onLogout
}) => {
  const classes = useStyles();
  const location = useLocation();

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = 'primary-search-account-menu';
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    let permissionArray = Object.keys(permissions.dashboard);
    permissionArray.forEach(element => {
      if(permissions.dashboard[element]){
      localStorage.setItem('permissions', element)
      }
    });
    // localStorage.setItem('permissions', JSON.stringify(permissions.dashboard))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box height="100%" display="flex" flexDirection="column">
            <Box
              // className="MuiAppBar-colorPrimary"
              className="root"
              display="flex"
              justifyContent="center"
              padding={1}
            >
              <a href="/">
                <Logo />
              </a>
              
            </Box>
            <Box alignItems="center" display="flex" flexDirection="column">

              
              <List>

                {navBarRoutes(permissions, userRole)
                  .filter(role => role.isVisible)
                  .map(route => (
                    <ListItemLink
                      href={route.link}
                      key={route.link}
                      style={{
                        display: 'inline-block',
                        textAlign: 'center',
                        width: '100%'
                      }}
                    >
                      <ListItemText primary={route.title} />
                    </ListItemLink>
                  ))}
                <Typography
                  variant="h5"
                  style={{ textAlign: 'center' }}
                  // noWrap
                  // key={Math.random()}
                >
                  <Link
                    style={{ color: 'black', fontWeight: '400' }}
                    onClick={handleClick}
                  >
                    Reports
                  </Link>
                </Typography>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  
                  {navBarRoutesAdmin(permissions, userRole)
                    .filter(role => role.isVisible)
                    .map(route => (
                      <Typography
                        className={classes.title}
                        variant="h5"
                        noWrap
                        key={Math.random()}
                      >
                        <Link href={route.link} className="root">
                          {route.title}
                        </Link>
                      </Typography>
                    ))}
                </Menu>

                <ListItemLink
                  onClick={() => onLogout()}
                  style={{
                    display: 'inline-block',
                    textAlign: 'center',
                    width: '100%'
                  }}
                >
                  <ListItemText primary="Logout" />
                </ListItemLink>
              </List>
            </Box>
            <Box flexGrow={1} />
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

NavBar.propTypes = {
  onLogout: PropTypes.func,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
