import React, { useEffect, useState } from 'react'
import TimePicker from './timepicker'
import {
    Card,
    CardContent,
    Grid,
    makeStyles,
    Typography,
    TextField,
    Paper,
    Button,
    Tooltip,
    IconButton,
    InputLabel,
    FormControl,
    Select,
    MenuItem

} from '@material-ui/core';
import moment from 'moment';
import Date from './DaterangeReport'
import { DataGrid,GridToolbar } from '@material-ui/data-grid';
import axios from 'axios'
import FileUpload from './Ivrfileupload'
import Showmodal from './Showmodal'
import { CAMPAIGN } from 'src/modules/dashboard-360/utils/endpoints'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { functionsIn } from 'lodash';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        margin: '1rem 2rem'
    },
    paddingTen: {
        padding: 10
    }
}));

// function getProperTime(str) {
//     // let dateStrs = "Fri Apr 20 2020 00:00:00 GMT+0530 (India Standard Time)"
//     let newD = new Date(str)
//     newD.setHours(newD.getHours()+5)
//     newD.setMinutes(newD.getMinutes()+30)
//     // console.log(newD)
//     return newD;
// }

const Campaign = (props) => {
    const classes = useStyles();
    // const [startTime, setStartTime] = useState("")
    // const [startTime, setStartTime] = useState(new Date())
    // const [endTime, setEndtime] = useState("")
    const [queue, setQueue] = useState("")
    const [campaignRetry, setRetry] = useState("")
    const [campaignName, setcampaignname] = useState("")
    // const [date, setdate] = useState("")
    const [campaigns, setCampaigns] = useState([])
    const [disable, setDisable] = useState(true)
    const [modaldata, setModaldata] = useState([])
    const [show, setShow] = useState(false)
    const [camformval, setcamformval] = useState(
        {
            "campaign_name": '',
            "queue": '',
            "startdate": '',
            "enddate": '',
            "retries": '',
            "status": '',
            "typeofCampaign": ''
        }
    )
    useEffect(() => {
        getCampaigns()
    }, [])

    const HandleFormchange = e => {
        const { name, value } = e.target;
        setcamformval(prevState => ({
            ...prevState,
            [name]: value
        }));
        
        if(Object.values(camformval).every((v) => v.length !== 0)) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    };

    const handleQueueChange = (e) => setQueue(e.target.value)
    const handleretryChange = (e) => {
        setRetry(e.target.value)
        setDisable(false)
    }

    const handlecampaignNameChange = (e) => setcampaignname(e.target.value)

    const handleReset = () => {
        // setdate("")
        // setStartTime("")
        // setEndtime("")
        // setQueue("")
        // setRetry("")
        // setcampaignname("")
        setcamformval({
            "campaign_name": '',
            "queue": '',
            "startdate": '',
            "enddate": '',
            "retries": '',
            "status": '',
            typeofCampaign:''
        })
    }

    // const handleTime = (time, label) => {
    //     alert(label)
    //     if (label === 'starttime') {
    //         console.log(time)
    //         const time1 = moment(time).format()
    //         const time2 = time1.replace('T', " ").slice(10, 19)
    //         setStartTime(time2)
    //     } else if (label === 'End time') {
    //         const time1 = moment(time).format()
    //         const time2 = time1.replace('T', " ").slice(10, 19)
    //         setEndtime(time2)
    //     }
    // }

    const handleAddCampaign = (e) => {
        // const data = {
        //     "campaign_name": campaignName,
        //     "queue": queue,
        //     "startdate": `${date}${startTime}`,
        //     "enddate": `${date}${endTime}`,
        //     "retries": campaignRetry,
        //     "status": "1"
        // }
        
        // let starttime = localStorage.getItem('starttime');
        // let Endtime = localStorage.getItem('Endtime');

        // let newD = new Date(starttime);
        // newD.setHours(newD.getHours()+5);
        // let startdate = newD.setMinutes(newD.getMinutes()+30);
        
        // newD = new Date(Endtime);
        // newD.setHours(newD.getHours()+5);
        // let enddate = newD.setMinutes(newD.getMinutes()+30);

        // if (camformval.campaign_name.length != 0 && ) {

        // }

            const data = {
                "campaign_name": camformval.campaign_name,
                "queue": camformval.queue,
                "startdate": camformval.startdate,
                "enddate": camformval.enddate,
                "retries": camformval.retries,
                "status": "1",
                typeofCampaign: camformval.typeofCampaign
            }
        // const data = {
        //     "campaign_name": campaignName,
        //     "queue": queue,
        //     "startdate": localStorage.getItem('starttime'),
        //     "enddate": localStorage.getItem('Endtime'),
        //     "retries": campaignRetry,
        //     "status": "1"
        // }

        axios.post(`${CAMPAIGN}/campaign/createCampaign`, data)
        .then((response) => {
            console.log(response.data)
            handleReset()
            getCampaigns()
            localStorage.removeItem('starttime');
            localStorage.removeItem('Endtime');
        })
        .catch((error) => {
            console.log(error)
        })
        console.log(data)

    }

    const updateCampaign = (id, status) => {
        const data = {
            "_id": id,
            "status": status

        }
        console.log(data, "fdsfsd")

        axios.post(`${CAMPAIGN}/campaign/updateCampaignbyID`, data)
            .then((response) => {
                console.log(response.data, "update")
                getCampaigns()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getCampaigns = () => {
        var axios = require('axios');
        var data = '';

        var config = {
            method: 'get',
            url: `${CAMPAIGN}/campaign/getAllCampaign`,
            headers: {},
            data: data
        };

        axios(config)
            .then(function (response) {
                if (response.data.Record.length > 0) {
                    var i = 0;
                    response.data.Record.map((ele) => {
                        i = i + 1;

                        var dateFormat = 'DD-MM-YYYY HH:mm:ss';
                        var endUtc = moment.utc(ele.enddate);
                        var startUtc = moment.utc(ele.startdate);
                        var localeDate = endUtc.local();
                        var localsDate = startUtc.local();
                        ele.enddate = localeDate.format(dateFormat);
                        ele.startdate = localsDate.format(dateFormat);
                        return ele.id = i;

                        // return ele.enddate === ele.enddate.slice(0, 10)
                    })
                    setCampaigns(response.data.Record.reverse())
                }
                // let rdata = response.data.reverse()
                // console.log()
                // rdata = rdata.reverse()
                // console.log(rdata)

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    // console.log(campaigns, "cammmm")
    const handleUpload = (data) => {
        console.log(data)
        // data.map((ele) => {
        //     console.log(ele)
        // })

    }

    const getformData =(campaign)=>{
        const data={
            "ivrCampaignName":campaign
        }
        axios.post(`/crm-route/Campaignformreports`,data)
            .then((response)=>{
                console.log(response,"form reforps")
            })
            .catch((err)=>{
                alert(`Error in fetching form reports ${err.message}`)
            })
    }

    const getdialerData =(campaign)=>{
        const data={
            "ivrCampaignName":campaign
        }
        axios.post(`/crm-route/Campaignformreports`,data)
            .then((response)=>{

            })
            .catch((err)=>{
                alert(`Error in fetching form reports ${err.message}`)
            })
    }

    const getnocalledData =(campaign)=>{
        const data={
            "ivrCampaignName":campaign
        }
        axios.post(`/crm-route/Campaignformreports`,data)
            .then((response)=>{
                console.log(response,"form reforps")
            })
            .catch((err)=>{
                alert(`Error in fetching form reports ${err.message}`)
            })
    }

    const profilesColumns = [
        {
            headerName: 'Name',
            field: 'campaign_name',
            flex: 0.5

        },
        {
            headerName: 'Start Date',
            field: 'startdate',
            flex: 0.5,

            // renderCell: rowData => (
            //     <>
            //         {
            //             (<div>rowData.row.status</div>)
            //             // rowData.row.status === '1' && (<div>
            //             //     <Tooltip title="Activated">
            //             //         <IconButton

            //             //         ><Typography>Inactive</Typography>
            //             //         </IconButton>
            //             //     </Tooltip>
            //             // </div>)
            //         }
            //         </>
            // ),
        },
        {
            headerName: 'End Date',
            field: 'enddate',
            flex: 0.3
        },
        {
            headerName: 'Type of Campaign',
            field: 'typeofCampaign',
            flex: 0.3
        },
        // {
        //     headerName: 'Start Time',
        //     field: 'startdate',
        //     flex: 0.5
        // },
        // {
        //     headerName: 'End Time',
        //     field: 'startdate',
        //     flex: 0.5
        // },
        {
            headerName: 'Queue',
            field: 'queue',
            flex: 0.3
        },
        {
            headerName: 'Retries',
            field: 'retries',
            flex: 0.3
        },
        {
            headerName: 'Status',
            field: '',

            renderCell: rowData => (
                <>
                    {
                        rowData.row.status === '1' && (<div>
                            <Tooltip title="Activated">
                                <IconButton

                                ><Typography>Inactive</Typography>
                                </IconButton>
                            </Tooltip>
                        </div>)
                    }
                    {
                        rowData.row.status === '0' && (<div>
                            <Tooltip title="Activate">
                                <IconButton
                                ><Typography>Active</Typography>
                                </IconButton>
                            </Tooltip>
                        </div>)
                    }
                    {
                        rowData.row.status === 'F' && (<div>
                            <Tooltip title="Finished">
                                <IconButton
                                ><Typography>Finished</Typography>
                                </IconButton>
                            </Tooltip>
                        </div>)
                    }
                </>
            ),
            flex: 0.3
        },
        {
            headerName: 'Actions',
            field: 'id',

            renderCell: rowData => (
                <>
                    {
                        rowData.row.status === '1' && (<div>
                            <Tooltip title="Activate">
                                <IconButton
                                    onClick={() => { updateCampaign(rowData.row._id, "0") }}
                                ><Button variant="contained" >Activate</Button>
                                </IconButton>
                            </Tooltip>
                        </div>)
                    }
                    {
                        rowData.row.status === '0' && (<div>
                            <Tooltip title="Deactivate">
                                <IconButton
                                    onClick={() => { updateCampaign(rowData.row._id, "1") }}
                                ><Button variant="contained" >Deactivate</Button>
                                </IconButton>
                            </Tooltip>
                        </div>)
                    }
                </>
            ),
            flex: 0.3
        },
        {
            headerName: 'Upload',
            field: 'Script',

            renderCell: rowData => (
                <>
                    <FileUpload id={rowData.row._id} typeofCampaign={rowData.row.typeofCampaign} retries={rowData.row.retries} campaignID={rowData.row.campaign_name} handleUpload={handleUpload} />
                </>
            ),
            flex: 0.3
        },
        


    ];


    const showProfile = (data) => {
        console.log("data", data.row.campaign_name)
        var axios = require('axios');
        data = JSON.stringify({ "ivrCampaignName": data.row.campaign_name });

        var config = {
            method: 'post',
            url: `${CAMPAIGN}/channel/getBycampaign`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                //  if(response.data.counts.length>0){
                setModaldata(response.data.counts);
                setShow(true)
                //  }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleClose = () => {
        setShow(false)
    }



    return (<div>
        <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={0}></Grid>
            <Grid item lg={4} md={12} xs={0}></Grid>
            <Grid item lg={6} md={12} xs={0}>
                <h2>&nbsp; Autodailer Campaign Admin Dashboard</h2>
            </Grid>
            <Grid item lg={2} md={12} xs={0}></Grid>
        </Grid>
        <Paper className={classes.root}>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item lg={3} md={3} xs={6} style={{padding: 10}}>
                            {/* <TimePicker label="starttime" value={startTime} handleTime={handleTime}/ > */}
                            {/* {camformval.startdate.length != 0 ? camformval.startTime.slice(0,10): null } {camformval.startdate.length != 0 ?  camformval.startTime.slice(11): null} */}
                            <label>Start Time</label>
                            <input  type="datetime-local" name='startdate' label="starttime" value={camformval.startTime} onChange={HandleFormchange}  style={{padding: 10}}/>
                        </Grid>
                        <Grid item lg={3} md={3} xs={6} style={{padding: 10}}>
                            {/* <TimePicker label="Endtime" value={endTime} handleTime={handleTime} /> */}
                            <label>End Time</label>
                            {/* <input type="datetime-local" name='enddate' label="endTime" value={endTime} onChange={(e)=>setEndtime(e.target.value)} /> */}
                            <input type="datetime-local" name='enddate' label="endTime" value={camformval.endTime} onChange={HandleFormchange}  style={{padding: 10}}/>
                        </Grid>
                        <Grid item lg={3} md={3} xs={6} style={{padding: 10}}>
                            {/* <TextField id="outlined-basic" label="Queue" variant="outlined" value={queue} onChange={handleQueueChange} />                             */}
                            <FormControl variant="outlined" className={classes.formControl} 
                        style={{width:"100%"}}>
                    <InputLabel id="demo-simple-select-outlined-label">Type of Call</InputLabel>
                    <Select labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={camformval.typeofCampaign}
                        onChange={HandleFormchange}
                        label="typeofCampaign"
                        name='typeofCampaign'
                        autoWidth="true">
                        <MenuItem value="autodailer">Autodailer</MenuItem>
                        <MenuItem value="click_to_call">Click to Call</MenuItem>
                    </Select>
                </FormControl>
                           </Grid>
                        <Grid item lg={3} md={3} xs={6} style={{padding: 10}}>
                            {/* <TextField id="outlined-basic" label="Queue" variant="outlined" value={queue} onChange={handleQueueChange} />                             */}
                            <FormControl variant="outlined" className={classes.formControl} 
                        style={{width:"100%"}}>
                    <InputLabel id="demo-simple-select-outlined-label">Queue</InputLabel>
                    <Select labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={camformval.queue}
                        onChange={HandleFormchange}
                        label="Queue"
                        name='queue'
                        autoWidth="true">
                        <MenuItem value="1000">1000 - Outbound</MenuItem>
                        <MenuItem value="1001">1001 - Outbound</MenuItem>
                        <MenuItem value="1002">1002 - Outbound</MenuItem>
                        <MenuItem value="1003">1003 - Inbound</MenuItem>
                    </Select>
                </FormControl>
                        </Grid>
                        <Grid item lg={3} md={3} xs={6} style={{padding: 10}}>
                        <TextField id="outlined-basic" name='campaign_name' label="Campaign Name" variant="outlined" value={camformval.campaignName} onChange={HandleFormchange} />
                        </Grid>
                        <Grid item lg={3} md={3} xs={6} style={{padding: 10}}>
                            <TextField id="outlined-basic" name='retries' type="number" label="Campaign Retry" variant="outlined" value={camformval.campaignRetry} onChange={HandleFormchange} />
                        </Grid>
                        <Grid item lg={12} md={12} xs={12} style={{padding: 10, display:"flex", justifyContent: "center"}}>
                            <Button variant="contained" color="primary" onClick={handleAddCampaign} disabled={disable} >
                                Add Campaign
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Paper>
        <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12}>
                <Card>
                    <CardContent>
                        <div style={{ height: 500, width: '100%' }}>
                            <DataGrid rows={campaigns} columns={profilesColumns} components={{
                                Toolbar: GridToolbar,

                            }}pageSize={20}
                                // rowsPerPageOptions={[10, 20, 50]}
                                onRowClick={showProfile}
                                pagination
                                
                                 />
                        </div>

                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        {show === true ? <Showmodal show={show} modaldata={modaldata} handleClose={handleClose} /> : <></>}
    </div>)
}

export default Campaign