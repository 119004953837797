import routes from 'src/modules/dashboard-360/routes';
import uploadRoutes from 'src/modules/upload/routes';
// import campaignRoutes from 'src/modules/campaign/routes';
import React from 'react';
import telephonyRoutes from '../modules/telephony/routes';
// import ticketingRoutes from '../modules/ticketing/routes';
import adminRoutes from '../modules/admin/routes';
import authRoutes from '../modules/auth/routes';
import userRoutes from '../modules/user/routes';
import CommonAlert from 'src/components/CommonAlert';
import Inbound from 'src/components/Auto-report/manageagents'
import liveagent from 'src/components/Auto-report/liveagents'
import Interaction from 'src/components/Auto-report/interactionreport'
import AgentPerformance from 'src/components/Auto-report/agentperformance'
import CampaignDashboard from 'src/components/campaign/ivrcampaign'
// import CampaignReport from 'src/components/campaign/campaignreport'
import jobreport from 'src/components/campaign/jobDetails'
import Campaign1 from 'src/components/campaign/campaign1'
import Interaction1 from 'src/components/Auto-report/interaction-res'
import Report  from 'src/components/Daily-report/daily-report.js';
import Reports from 'src/modules/dashboard-360/views/admin/report/index';
import ManualInteractionReports from 'src/components/manualCallReports/manualInteractionReports.js'
import Recordings from 'src/components/recordings/recordings';
import ZohoReports from 'src/components/zohoReports/ZohoReports';
import RNRNumbersCount from 'src/components/RNR/RNR';

export const dash360 = React.lazy(() =>
  import('src/modules/dashboard-360/views')
);
export const upload = React.lazy(() => import('src/modules/upload'));
// export const surveys = React.lazy(() => import('src/modules/surveys'));
export const telephony = React.lazy(() =>
  import('src/modules/telephony/views/index')
);
// export const ticketing = React.lazy(() =>
//   import('src/modules/ticketing/views')
// );
// export const campaign = React.lazy(() => import('src/modules/campaign'));
export const admin = React.lazy(() => import('src/modules/admin'));
export const adminL2 = React.lazy(() => import('src/modules/dashboard-360/views/L2'));
export const auth = React.lazy(() => import('src/modules/auth/views/index'));
export const user = React.lazy(() => import('src/modules/user/views/index'));

export default [
  // {
  //   path: '/recordings/cdr',
  //   routes: adminRoutes,
  //   key: 'admin',
  //   component: Recordings,
  //   crumb: 'Admin',
  //   requiresAuth: true
  // },
  {
    path: '/dash360',
    routes,
    key: 'dash360',
    component: dash360,
    // crumb: 'L1',
    requiresAuth: true
    /* selector: 'dashboard.canViewDashboard'*/
  },
  {
    path: '/upload',
    routes: uploadRoutes,
    key: 'upload',
    component: upload,
    crumb: 'Upload',
    requiresAuth: true
  },
  
  {
    path: '/telephony',
    routes: telephonyRoutes,
    key: 'telephony',
    component: telephony,
    crumb: 'Telephony',
    requiresAuth: true
  },
  {
    path: '/admin',
    routes: adminRoutes,
    key: 'admin',
    component: admin,
    crumb: 'Admin',
    requiresAuth: true
  },
  {
    path: '/dailyreport',
    routes: adminRoutes,
    key: 'admin',
    component: Report,
    crumb: 'Admin',
    requiresAuth: true
  },{
    path: '/liveAgents',
    routes: adminRoutes,
    key: 'admin',
    component: liveagent,
    crumb: 'Admin',
    requiresAuth: true
},
  {
    path: '/manageAgents',
    routes: adminRoutes,
    key: 'admin',
    component: Inbound,
    crumb: 'Admin',
    requiresAuth: true
},
{
  path: '/recordings/cdr',
  routes: adminRoutes,
  key: 'admin',
  component: Recordings,
  crumb: 'Admin',
  requiresAuth: true
},
{
  path: '/RNR/Calls',
  routes: adminRoutes,
  key: 'admin',
  component: RNRNumbersCount,
  crumb: 'Admin',
  requiresAuth: true
},
{
  path: '/zohoreports/cdr',
  routes: adminRoutes,
  key: 'admin',
  component: ZohoReports,
  crumb: 'Admin',
  requiresAuth: true
},

{
  path: '/addNewUser',
  routes: adminRoutes,
  key: 'admin',
  component: Inbound,
  crumb: 'Add new user',
  requiresAuth: true
},
{
  path: '/campaignreport',
  routes: adminRoutes,
  key: 'admin',
  component: Campaign1,
  crumb: 'Admin',
  requiresAuth: true
},
{
  path: '/interaction-res',
  routes: adminRoutes,
  key: 'admin',
  component: Interaction1,
  crumb: 'Admin',
  requiresAuth: true
},
{
  path: '/jobreport',
  routes: adminRoutes,
  key: 'admin',
  component: jobreport,
  crumb: 'Admin',
  requiresAuth: true
},
{
  path: '/campaign',
  routes: adminRoutes,
  key: 'admin',
  component: CampaignDashboard,
  crumb: 'Admin',
  requiresAuth: true
},
{
  path: '/interactionreport',
  routes: adminRoutes,
  key: 'admin',
  component: Interaction,
  crumb: 'Admin',
  requiresAuth: true
},
{
  path: '/AgentPerformance',
  routes: adminRoutes,
  key: 'admin',
  component: AgentPerformance,
  crumb: 'Admin',
  requiresAuth: true
},
{
  path: '/manualInteractionReports',
  routes: adminRoutes,
  key: 'admin',
  component: ManualInteractionReports,
  crumb: 'Admin',
  requiresAuth: true
},

  {
    path: '/auth',
    routes: authRoutes,
    key: 'auth',
    component: auth,
    requiresAuth: false
  },
  {
    path: '/user',
    routes: userRoutes,
    key: 'user',
    component: user,
    requiresAuth: true
  },
  {
    path: '/report',
    routes: adminRoutes,
    key: 'admin',
    component: Reports,
    crumb: 'Reports',
    requiresAuth: true
  },
  {
    path: '/404',
    routes: [],
    key: '404',
    component: CommonAlert,
    requiresAuth: true
  }
];
