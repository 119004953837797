import map from 'lodash/map';

export function getAddressFromObj(obj) {
  return `${obj.address1}, ${obj.address2}, ${obj.BillingTownArea}, ${obj.BillingCityName}, ${obj.BillingStateName}, ${obj.BillingCountryName}, ${obj.BillingPin}`;
}

export function getDependentQuestionsCodes(options, dependentQuesCodes) {
  for (let opt of options) {
    if (opt.dependentQuestion) {
      //dependentQuesCodes = [...dependentQuesCodes, ...map(opt.dependentQuestion,'questionCode')]
      dependentQuesCodes.push(...map(opt.dependentQuestion, 'questionCode'));
      for (let depQue of opt.dependentQuestion) {
        getDependentQuestionsCodes(depQue.option, dependentQuesCodes);
      }
    }
  }
  return dependentQuesCodes;
}

export function getDispositionFormQuestions5() {
  const questionArr = [
    {
      questionCode: 'mainDisposition',
      question: 'Main Disposition',
      questionName: 'QA_1',
      option: [
        {
          label: 'Connected',
          dependentQuestion: [
            {
              questionCode: 'Activity',
              question: 'Activity',
              questionName: 'QA_3',
              option: [
                {
                  label: 'Joined competition',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'Joined Flipkart' },
                        { label: 'Joined Amazon' },
                        { label: 'Joined other Ecommerce platform' },
                        { label: 'Joined Swiggy' },
                        { label: 'Joined Zomato' },
                        { label: 'Joined Shadowfax' },
                        { label: 'High Pay Job in other sector' }
                      ]
                    }
                  ]
                },
                {
                  label: 'Payout Issue',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'Salary not received' },
                        { label: 'Fuel payment issue' },
                        { label: 'Delay in payouts' },
                        { label: 'Left due to low income' },
                        { label: 'Less Oder received' },
                        { label: 'MG not received ' }
                      ]
                    }
                  ]
                },
                {
                  label: 'Already Working',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'On Leaves' },
                        { label: 'Left for Personal Issue' },
                        { label: 'Out of station' }
                      ]
                    }
                  ]
                },
                {
                  label: 'Interested',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'Interested (Mention joining date)' },
                        { label: 'Not Joining due to distance issue' }
                      ]
                    }
                  ]
                },
                {
                  label: 'App/ Tech Issue',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'Distance Issue' },
                        { label: 'Tagged to wrong Zone' },
                        { label: 'Picup Location Issue ' },
                        { label: 'Zone Change required' },
                        { label: 'Mobile Issue' }
                      ]
                    }
                  ]
                },
                {
                  label: 'Bike Issue',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'No Bike' },
                        { label: 'Repair & Service' },
                        { label: 'Insurance & Documentation' }
                      ]
                    }
                  ]
                },
                {
                  label: 'Not Interested',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'Wont do ECOM delivery job' },
                        { label: 'College Started' },
                        { label: 'Working only on Weekends' },
                        { label: 'Personal Issue' },
                        { label: 'Will not do Food Delivery' }
                      ]
                    }
                  ]
                },
                {
                  label: 'Opps Issue',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'ID Block' },
                        { label: 'Login Issue' },
                        { label: 'ID creation issue' },
                        { label: 'OMT( Order Management Delay) Response Delay' }
                      ]
                    }
                  ]
                },
                {
                  label: 'No Contact/Call back',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'DND( do not disturb)' },
                        { label: 'Phone number not in use' },
                        { label: 'Out of Network coverage area' },
                        { label: 'Call Barred' },
                        { label: 'Call Disconnected' },
                        { label: 'Switch Off' },
                        { label: 'Talk Later' },
                        { label: 'Busy' },
                        { label: 'RNR' },
                        { label: 'Language barrier' },
                        { label: 'Will Join in future' }
                      ]
                    }
                  ]
                },
                {
                  label: 'Health Issue',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'Accident' },
                        { label: 'Fever/Covid' },
                        { label: 'Family Member unwell' }
                      ]
                    }
                  ]
                },
                {
                  label: 'Welcome/Health call/Health call 2',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'Contacted' },
                        { label: 'DND( do not disturb)' },
                        { label: 'Phone number not in use' },
                        { label: 'Out of Network coverage area' },
                        { label: 'Call Barred' },
                        { label: 'Call Disconnected' },
                        { label: 'Switch Off' },
                        { label: 'Talk Later' },
                        { label: 'Busy' },
                        { label: 'RNR' },
                        { label: 'Language barrier' }
                      ]
                    }
                  ]
                },
                {
                  label: 'Alternate Leads',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'Interested' },
                        { label: 'DND( do not disturb)' },
                        { label: 'Phone number not in use' },
                        { label: 'Out of Network coverage area' },
                        { label: 'Call Barred' },
                        { label: 'Call Disconnected' },
                        { label: 'Switch Off' },
                        { label: 'Talk Later' },
                        { label: 'Busy' },
                        { label: 'Not Interested' },
                        { label: 'RNR' },
                        { label: 'Language barrier' }
                      ]
                    }
                  ]
                },
                {
                  label: 'Job Portal',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'Interested' },
                        { label: 'DND( do not disturb)' },
                        { label: 'Phone number not in use' },
                        { label: 'Out of Network coverage area' },
                        { label: 'Call Barred' },
                        { label: 'Call Disconnected' },
                        { label: 'Switch Off' },
                        { label: 'Talk Later' },
                        { label: 'Not Interested' },
                        { label: 'Busy' },
                        { label: 'RNR' },
                        { label: 'Language barrier' }
                      ]
                    }
                  ]
                },
                {
                  label: 'Bitrix',
                  dependentQuestion: [
                    {
                      questionCode: 'ActivityOutcome',
                      question: 'Activity Outcome',
                      questionName: 'QA_5',
                      option: [
                        { label: 'Interested' },
                        { label: 'DND( do not disturb)' },
                        { label: 'Phone number not in use' },
                        { label: 'Out of Network coverage area' },
                        { label: 'Call Barred' },
                        { label: 'Call Disconnected' },
                        { label: 'Switch Off' },
                        { label: 'Talk Later' },
                        { label: 'Not Interested' },
                        { label: 'Busy' },
                        { label: 'RNR' },
                        { label: 'Will Join in future' }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          label: 'Not Connected',
          dependentQuestion: [
            {
              questionCode: 'subDisposition',
              question: 'Sub disposition',
              questionName: 'QA_2',
              option: [
                {
                  label: 'RNR'
                },
                {
                  label: 'Not Reachable'
                },
                {
                  label: 'Switch Off'
                },
                {
                  label: 'Wrong Number'
                },
                {
                  label: 'Call Disconnected'
                },
                {
                  label: 'Language Barrier'
                },
                {
                  label: 'Not Interested to share feedback'
                },
                {
                  label: 'Invalid number'
                },
                {
                  label: 'Busy'
                },
                {
                  label: 'Feedback taken'
                }
              ]
            }
          ]
        }
      ]
    },
    {
      questionCode: 'agentRemarks',
      question: 'Comments',
      questionName: 'QA_1108',
      questionType: 'textarea',
      additionalConfig: {
        rows: 4
      },
      option: [{}]
    }
  ];
  return questionArr;
}

export function getDispositionFormQuestionsManualCall() {
  const questionArr = [
    {
      questionCode: 'mainDisposition',
      question: 'Main Disposition',
      questionName: 'QA_1',
      option: [ {
        label: 'Connected',
        dependentQuestion: [{
          questionCode: 'Campiagn',
          question: 'Campiagn',
          questionName: 'QA_3',
          option: [ {
            label: 'Connected- Retention call',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Joined competition',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Joined Flipkart' },
                    { label: 'Joined Amazon' },
                    { label: 'Joined other Ecommerce platform' },
                    { label: 'Joined Swiggy' },
                    { label: 'Joined Zomato' },
                    { label: 'Joined Shadowfax' },
                    { label: 'High Pay Job in other sector' }
                  ],
                }],
              },
              {
                label: 'Payout Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Salary not received' },
                    { label: 'Fuel payment issue' },
                    { label: 'Delay in payouts' },
                  ],
                }],
              },
              {
                label: 'Already Working',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'On Leaves/Will Join in future' },
                    { label: 'Left for Personal Issue' },
                    { label: 'Out of station' },
                  ],
                }],
              },
              {
                label: 'Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Interested (Mention joining date)' },
                    { label: 'Not Joining due to distance issue' },
                  ],
                }],
              },
              {
                label: 'App/ Tech Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Login Issue' },
                    { label: 'Picup Location Issue' },
                  ],
                }],
              },
              {
                label: 'Bike Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'No Bike' },
                    { label: 'Repair & Service' },
                    { label: 'Insurance & Documentation' },
                  ],
                }],
              },
              {
                label: 'Not Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: "Won't do ECOM delivery job" },
                    { label: 'College Started' },
                    { label: 'Working only on Weekends' },
                    { label: 'Personal Issue' },
                    { label: 'Will not do Food Delivery' },
                    { label: 'Others' },
                  ],
                }],
              },
              {
                label: 'Opps Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Zone Change required' },
                    { label: 'ID Block' },
                    { label: 'Tagged to wrong Zone' },
                  ],
                }],
              },
              {
                label: 'Health Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Accident' },
                    { label: 'Fever/Covid' },
                    { label: 'Family Member unwell' },
                  ],
                }],
              },
              {
                label: 'Product',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Will Join in future' },
                    { label: 'Distance Issue' },
                    { label: 'MG not received' },
                    { label: 'Rate card issue' },
                    { label: 'Left due to low income' },
                    { label: 'OMT( Order Management Delay) Response Delay' },
                    { label: 'Less Oder received' },
                  ],
                }],
              },
              {
                label: 'Onboarding team',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'ID creation issue' },
                    { label: 'Delay in Onboarding' }
                  ],
                }],
              }
            ],
            }],
          },
          {
            label: 'Connected- Grivance call',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Payout Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Salary not received' },
                    { label: 'Fuel payment issue' },
                    { label: 'Delay in payouts' },
                  ],
                }],
              },
              {
                label: 'App/ Tech Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'KYC not getting verified' },
                    { label: 'Not able to upload pic' },
                  ],
                }],
              },
              {
                label: 'Opps Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Zone Change required' },
                    { label: 'Location issue' },
                    { label: 'ID Block' },
                    { label: 'TL not responding' },
                  ],
                }],
              },
              {
                label: 'OBT',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'KYC not updated' },
                    { label: 'ID creation issue' },
                    { label: 'Tagged to wrong Zone' },
                  ],
                }],
              },
              {
                label: 'Product',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Distance Issue' },
                  { label: 'MG not received' },
                  { label: 'Rate card issue' },
                  { label: 'Left due to low income' },
                  { label: 'OMT( Order Management Delay) Response Delay' },
                  { label: 'Less Oder received' },
                  ],
                }],
              },
              {
                label: 'Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Interested (Mention joining date)' },
                    { label: 'Not Joining due to distance issue' },
                  ],
                }],
              },
              {
                label: 'Not Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: "Won't do ECOM delivery job" },
                    { label: 'College Started' },
                    { label: 'Working only on Weekends' },
                    { label: 'Personal Issue' },
                    { label: 'Will not do Food Delivery' },
                  ],
                }],
              },
            ],
            }],
          },
          {
            label: 'Connected- Leads Generation',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Interested (Onboarding form filled)' },
                    { label: 'Interested (Watting for Documents)' },
                    { label: 'Interested (Issue- Age/No bike/Distance issue)' },
                    { label: 'Interested (Not ready to share documents)' },
                  ],
                }],
              },
              {
                label: 'Not Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Distance Issue/ Location issue' },
                    { label: 'Applied in error' },
                    { label: 'Not intrested in Delivery job' },
                    { label: 'Not intrested as Less payout' },
                    { label: 'Not intrested as Working somewere else' },
                  ],
                }],
              },
              {
                label: 'Will Join in future',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'No Bike' },
                    { label: 'Health Issues' },
                    { label: 'Working somewere else' },
                    { label: 'Confirm  and will come back to inform' },
                  ],
                }],
              },
            ],
            }],
          },
          {
            label: 'Connected- Adoc work',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Frazoo',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Informed to mark attendance' },
                    { label: 'Night Shift' },
                    { label: 'Week Off' },
                    { label: 'Call connected' },
                  ],
                }],
              },{
                label: 'Extra calling Request',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Call connected' },
                  ],
                }],
              }
            ],
            }],
          },
          {
            label: 'Connected- Tranning',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Traning',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Joined Flipkart' },
                    { label: 'Kyc verification Completed' },
                    { label: 'KYC pending' },
                    { label: 'Traning pending' },
                    { label: 'Traning Completed' },
                  ],
                }],
              }],
            }],
          },
          // =============
          {
            label: 'Connected - Hand-holding call',
            dependentQuestion: [
                {
                    questionCode: 'SubCateogry',
                    question: 'SubCateogry',
                    questionName: 'QA_3',
                    option: [
                        {
                            label: 'Already Working',
                            dependentQuestion: [
                                {
                                    questionCode: 'SubCategory2',
                                    question: 'SubCategory2',
                                    questionName: 'QA_5',
                                    option: [
                                        {
                                            label: 'On Leaves/Will Join in future'
                                        },
                                        {
                                            label: 'Left for Personal Issue'
                                        },
                                        {
                                            label: 'Out of station'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Joined competition',
                            dependentQuestion: [
                                {
                                    questionCode: 'SubCategory2',
                                    question: 'SubCategory2',
                                    questionName: 'QA_5',
                                    option: [
                                        {
                                            label: 'Joined Flipkart'
                                        },
                                        {
                                            label: 'Joined Amazon'
                                        },
                                        {
                                            label: 'Joined other Ecommerce platform'
                                        },
                                        {
                                            label: 'Joined Swiggy'
                                        },
                                        {
                                            label: 'Joined Zomato'
                                        },
                                        {
                                            label: 'Joined Shadowfax'
                                        },
                                        {
                                            label: 'High Pay Job in other sector'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Payout Issue',
                            dependentQuestion: [
                                {
                                    questionCode: 'SubCategory2',
                                    question: 'SubCategory2',
                                    questionName: 'QA_5',
                                    option: [
                                        {
                                            label: 'Salary not received'
                                        },
                                        {
                                            label: 'Fuel payment issue'
                                        },
                                        {
                                            label: 'Delay in payouts'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'App/ Tech Issue',
                            dependentQuestion: [
                                {
                                    questionCode: 'SubCategory2',
                                    question: 'SubCategory2',
                                    questionName: 'QA_5',
                                    option: [
                                        {
                                            label: 'Login Issue'
                                        },
                                        {
                                            label: 'Pickup Location Issue '
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Bike Issue',
                            dependentQuestion: [
                                {
                                    questionCode: 'SubCategory2',
                                    question: 'SubCategory2',
                                    questionName: 'QA_5',
                                    option: [
                                        {
                                            label: 'No Bike'
                                        },
                                        {
                                            label: 'Repair & Service'
                                        },
                                        {
                                            label: 'Insurance & Documentation'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Not Interested',
                            dependentQuestion: [
                                {
                                    questionCode: 'SubCategory2',
                                    question: 'SubCategory2',
                                    questionName: 'QA_5',
                                    option: [
                                        {
                                            label: 'Wont do ECOM delivery job'
                                        },
                                        {
                                            label: 'College Started'
                                        },
                                        {
                                            label: 'Working only on Weekends'
                                        },
                                        {
                                            label: 'Personal Issue'
                                        },
                                        {
                                            label: 'Will not do Food Delivery'
                                        },
                                        {
                                            label: 'Others'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Opps Issue',
                            dependentQuestion: [
                                {
                                    questionCode: 'SubCategory2',
                                    question: 'SubCategory2',
                                    questionName: 'QA_5',
                                    option: [
                                        {
                                            label: 'Zone Change required'
                                        },
                                        {
                                            label: 'ID Block'
                                        },
                                        {
                                            label: 'Tagged to wrong Zone'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Onboarding team',
                            dependentQuestion: [
                                {
                                    questionCode: 'SubCategory2',
                                    question: 'SubCategory2',
                                    questionName: 'QA_5',
                                    option: [
                                        {
                                            label: 'ID creation issue'
                                        },
                                        {
                                            label: 'Delay in Onboarding'
                                        },
                                        {
                                            label: 'Dual ID Creation'
                                        },
                                        {
                                            label: 'KYC not updated'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Earning Issue',
                            dependentQuestion: [
                                {
                                    questionCode: 'SubCategory2',
                                    question: 'SubCategory2',
                                    questionName: 'QA_5',
                                    option: [
                                        {
                                            label: 'Rate Card Issue'
                                        },
                                        {
                                            label: 'MG not received'
                                        },
                                        {
                                            label: 'Left due to low income'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            label: 'Product',
                            dependentQuestion: [
                                {
                                    questionCode: 'SubCategory2',
                                    question: 'SubCategory2',
                                    questionName: 'QA_5',
                                    option: [
                                        {
                                            label: 'Oder Management Delay'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
          },
          {
              label: 'Connected- ID Created but NO FOD',
              dependentQuestion: [
                  {
                      questionCode: 'SubCateogry',
                      question: 'SubCateogry',
                      questionName: 'QA_3',
                      option: [
                          {
                              label: 'Already Working',
                              dependentQuestion: [
                                  {
                                      questionCode: 'SubCategory2',
                                      question: 'SubCategory2',
                                      questionName: 'QA_5',
                                      option: [
                                          {
                                              label: 'On Leaves/Will Join in future'
                                          },
                                          {
                                              label: 'Left for Personal Issue'
                                          },
                                          {
                                              label: 'Out of station'
                                          }
                                      ]
                                  }
                              ]
                          },
                          {
                              label: 'Joined competition',
                              dependentQuestion: [
                                  {
                                      questionCode: 'SubCategory2',
                                      question: 'SubCategory2',
                                      questionName: 'QA_5',
                                      option: [
                                          {
                                              label: 'Joined Flipkart'
                                          },
                                          {
                                              label: 'Joined Amazon'
                                          },
                                          {
                                              label: 'Joined other Ecommerce platform'
                                          },
                                          {
                                              label: 'Joined Swiggy'
                                          },
                                          {
                                              label: 'Joined Zomato'
                                          },
                                          {
                                              label: 'Joined Shadowfax'
                                          },
                                          {
                                              label: 'High Pay Job in other sector'
                                          }
                                      ]
                                  }
                              ]
                          },
                          {
                              label: 'Payout Issue',
                              dependentQuestion: [
                                  {
                                      questionCode: 'SubCategory2',
                                      question: 'SubCategory2',
                                      questionName: 'QA_5',
                                      option: [
                                          {
                                              label: 'Salary not received'
                                          },
                                          {
                                              label: 'Fuel payment issue'
                                          },
                                          {
                                              label: 'Delay in payouts'
                                          }
                                      ]
                                  }
                              ]
                          },
                          {
                              label: 'App/ Tech Issue',
                              dependentQuestion: [
                                  {
                                      questionCode: 'SubCategory2',
                                      question: 'SubCategory2',
                                      questionName: 'QA_5',
                                      option: [
                                          {
                                              label: 'Login Issue'
                                          },
                                          {
                                              label: 'Picup Location Issue '
                                          }
                                      ]
                                  }
                              ]
                          },
                          {
                              label: 'Bike Issue',
                              dependentQuestion: [
                                  {
                                      questionCode: 'SubCategory2',
                                      question: 'SubCategory2',
                                      questionName: 'QA_5',
                                      option: [
                                          {
                                              label: 'No Bike'
                                          },
                                          {
                                              label: 'Repair & Service'
                                          },
                                          {
                                              label: 'Insurance & Documentation'
                                          }
                                      ]
                                  }
                              ]
                          },
                          {
                              label: 'Not Interested',
                              dependentQuestion: [
                                  {
                                      questionCode: 'SubCategory2',
                                      question: 'SubCategory2',
                                      questionName: 'QA_5',
                                      option: [
                                          {
                                              label: 'Wont do ECOM delivery job'
                                          },
                                          {
                                              label: 'College Started'
                                          },
                                          {
                                              label: 'Working only on Weekends'
                                          },
                                          {
                                              label: 'Personal Issue'
                                          },
                                          {
                                              label: 'Will not do Food Delivery'
                                          },
                                          {
                                              label: 'Others'
                                          }
                                      ]
                                  }
                              ]
                          },
                          {
                              label: 'Opps Issue',
                              dependentQuestion: [
                                  {
                                      questionCode: 'SubCategory2',
                                      question: 'SubCategory2',
                                      questionName: 'QA_5',
                                      option: [
                                          {
                                              label: 'Zone Change required'
                                          },
                                          {
                                              label: 'ID Block'
                                          },
                                          {
                                              label: 'Tagged to wrong Zone'
                                          }
                                      ]
                                  }
                              ]
                          },
                          {
                              label: ' Onboarding team ',
                              dependentQuestion: [
                                  {
                                      questionCode: 'SubCategory2',
                                      question: 'SubCategory2',
                                      questionName: 'QA_5',
                                      option: [
                                          {
                                              label: 'ID creation issue'
                                          },
                                          {
                                              label: 'Delay in Onboarding'
                                          },
                                          {
                                              label: 'Not aware of LS & onboarding'
                                          },
                                          {
                                              label: 'Dual ID Creation'
                                          },
                                          {
                                              label: 'KYC not updated'
                                          }
                                      ]
                                  }
                              ]
                          },
                          {
                              label: 'Earning Issue ',
                              dependentQuestion: [
                                  {
                                      questionCode: 'SubCategory2',
                                      question: 'SubCategory2',
                                      questionName: 'QA_5',
                                      option: [
                                          {
                                              label: 'Rate Card Issue'
                                          },
                                          {
                                              label: 'MG not received'
                                          },
                                          {
                                              label: 'Left due to low income'
                                          }
                                      ]
                                  }
                              ]
                          },
                          {
                              label: 'Product',
                              dependentQuestion: [
                                  {
                                      questionCode: 'SubCategory2',
                                      question: 'SubCategory2',
                                      questionName: 'QA_5',
                                      option: [
                                          {
                                              label: 'Order Management Delay'
                                          }
                                      ]
                                  }
                              ]
                          }
                      ]
                  }
              ]
          },
          // ============
        ],
        }],
      },
      {
        label: 'Not Connected',
        dependentQuestion: [{
          questionCode: 'Campiagn',
          question: 'Campiagn',
          questionName: 'QA_3',
          option: [ {
            label: 'Not connected- Retention call',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [  
                { label: 'DND( do not disturb)' },
                { label: 'Phone number not in use' },
                { label: 'Out of Network coverage area' },
                { label: 'Call Barred' },
                { label: 'Call Disconnected' },
                { label: 'Switch Off' },
                { label: 'Talk Later/Call Back' },
                { label: 'Busy' },
                { label: 'RNR' },
                { label: 'Language barrier' },
                { label: 'Incorrect number' },
                { label: 'Left Msg' },
                { label: 'Dead air/ No responce from Caller' },
            ],
            }],
          },
          {
            label: 'Not connected- Grivance call',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [  
                { label: 'DND( do not disturb)' },
                { label: 'Phone number not in use' },
                { label: 'Out of Network coverage area' },
                { label: 'Call Barred' },
                { label: 'Call Disconnected' },
                { label: 'Switch Off' },
                { label: 'Talk Later/Call Back' },
                { label: 'Busy' },
                { label: 'RNR' },
                { label: 'Language barrier' },
                { label: 'Incorrect number' },
                { label: 'Left Msg' },
                { label: 'Dead air/ No responce from Caller' },
            ],
            }],
          },
          {
            label: 'Not connected- Leads Generation',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ 
                { label: 'DND( do not disturb)' },
                { label: 'Phone number not in use' },
                { label: 'Out of Network coverage area' },
                { label: 'Call Barred' },
                { label: 'Call Disconnected' },
                { label: 'Switch Off' },
                { label: 'Talk Later/Call Back' },
                { label: 'Busy' },
                { label: 'RNR' },
                { label: 'Language barrier' },
                { label: 'Incorrect number' },
                { label: 'Left Msg' },
                { label: 'Dead air/ No responce from Caller' },
                // {label: 'Dead air/ No responce from Caller'}
            ],
            }],
          },
          {
            label: 'Not Connected- Adoc work',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ 
                { label: 'DND( do not disturb)' },
                { label: 'Phone number not in use' },
                { label: 'Out of Network coverage area' },
                { label: 'Call Barred' },
                { label: 'Call Disconnected' },
                { label: 'Switch Off' },
                { label: 'Talk Later/Call Back' },
                { label: 'Busy' },
                { label: 'RNR' },
                { label: 'Language barrier' },
                { label: 'Incorrect number' },
                { label: 'Left Msg' },
                { label: 'Dead air/ No responce from Caller' },
              ],
            }],
          },
          {
            label: 'Not Connected- Tranning',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_5',
              option: [ 
                { label: 'DND( do not disturb)' },
                { label: 'Phone number not in use' },
                { label: 'Out of Network coverage area' },
                { label: 'Call Barred' },
                { label: 'Call Disconnected' },
                { label: 'Switch Off' },
                { label: 'Talk Later/Call Back' },
                { label: 'Busy' },
                { label: 'RNR' },
                { label: 'Language barrier' },
                { label: 'Incorrect number' },
                { label: 'Left Msg' },
                { label: 'Dead air/ No responce from Caller' },
              ],
            }],
          },
          {
            label: 'Not connected - Hand-Holding Call',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_5',
              option: [ 
                { label: 'DND( do not disturb)' },
                { label: 'Phone number not in use' },
                { label: 'Out of Network coverage area' },
                { label: 'Call Barred' },
                { label: 'Call Disconnected' },
                { label: 'Switch Off' },
                { label: 'Talk Later/Call Back' },
                { label: 'Busy' },
                { label: 'RNR' },
                { label: 'Language barrier' },
                { label: 'Incorrect number' },
                { label: 'Left Msg' },
                { label: 'Dead air/ No responce from Caller' },
              ],
            }],
          },
          {
            label: 'Not connected - ID Created but NO FOD',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_5',
              option: [ 
                { label: 'DND( do not disturb)' },
                { label: 'Phone number not in use' },
                { label: 'Out of Network coverage area' },
                { label: 'Call Barred' },
                { label: 'Call Disconnected' },
                { label: 'Switch Off' },
                { label: 'Talk Later/Call Back' },
                { label: 'Busy' },
                { label: 'RNR' },
                { label: 'Language barrier' },
                { label: 'Incorrect number' },
                { label: 'Left Msg' },
                { label: 'Dead air/ No responce from Caller' },
              ],
            }],
          },
        ],
        }],
      },

// ===================


      ]
    },
    {
      questionCode: 'agentRemarks',
      question: 'Comments',
      questionName: 'QA_1108',
      questionType: 'textarea',
      additionalConfig: {
        rows: 4
      },
      option: [{}]
    }
  ];
  return questionArr;
}

export function getDispositionFormQuestionsManualCallL2() {
  const questionArr = [
    {
      questionCode: 'mainDisposition',
      question: 'Main Disposition',
      questionName: 'QA_1',
      option: [ {
        label: 'Connected',
        dependentQuestion: [{
          questionCode: 'Campiagn',
          question: 'Campiagn',
          questionName: 'QA_3',
          option: [
            {
              label: 'Unable to Login',
              dependentQuestion: [{
                questionCode: 'Sub_disposition',
                question: 'Sub_disposition',
                questionName: 'QA_3',
                option: [
                  {
                    'label': 'App issue'
                  },
                  {
                    'label': 'ID Block'
                  },
              ]
              }]
                
            },
            {
              label: 'DE Attrition',
              dependentQuestion: [{
                questionCode: 'Sub_disposition',
                question: 'Sub_disposition',
                questionName: 'QA_3',
                option: [
                  {
                    'label': 'Health Issue'
                  },
                  {
                    'label': 'Went to  Native'
                  },
                  {
                    'label': 'Bike/Mobile issue'
                  },
                  {
                    'label': 'Personal Issue'
                  },
                  {
                    'label': 'Joined Another Company'
                  },
                  {
                    'label': 'On Leave'
                  },
                  {
                    'label': 'Others issue'
                  },
  
              ]
              }]
            },
            {
              label: 'MDI/Governance',
            },
            {
              label: 'Onboarding/Welcome Call',
              
            },
            {
              label: 'Order Status',
            },
            {
              label: 'Payout issue',
              dependentQuestion: [{
                questionCode: 'Sub_disposition',
                question: 'Sub_disposition',
                questionName: 'QA_3',
                option: [
                  {
                    'label': 'Payout Not credited'
                  },
                  {
                    'label': 'Incorrect Payout received'
                  },
                  {
                    'label': 'Incentive issue'
                  },
                  {
                    'label': 'Extra Surge'
                  },
              ]
              }]
            },
            {
              label: 'Other/General issues',
            },
            {
              label: 'Grievances & Issues',
            },
            
          ],
        }],
      },
      {
        label: 'Not Connected',
        dependentQuestion: [{
          questionCode: 'Campaign',
          question: 'Campaign',
          questionName: 'QA_3',
          option: [
            {
              'label': 'RNR'
            },
            {
              'label': 'Switched off'
            },
            {
              'label': 'Out of Network  Coverage Area'
            },
            {
              'label': 'Call Barred'
            },
            {
              'label': 'Call Disconnected'
            },
            {
              'label': 'Busy'
            },

        ]
      }]
      },

// ===================


      ]
    },
    {
      questionCode: 'agentRemarks',
      question: 'Comments',
      questionName: 'QA_1108',
      questionType: 'textarea',
      additionalConfig: {
        rows: 4
      },
      option: [{}]
    }
  ];
  return questionArr;
}

export function getDispositionFormQuestionsManualCalls() {
  const questionArr = [
    {
      questionCode: 'mainDisposition',
      question: 'Main Disposition',
      questionName: 'QA_1',
      option: [ {
        label: 'Connected',
        dependentQuestion: [{
          questionCode: 'Campiagn',
          question: 'Campiagn',
          questionName: 'QA_3',
          option: [ {
            label: 'Connected- Retention call',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Joined competition',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Joined Flipkart' },
                    { label: 'Joined Amazon' },
                    { label: 'Joined other Ecommerce platform' },
                    { label: 'Joined Swiggy' },
                    { label: 'Joined Zomato' },
                    { label: 'Joined Shadowfax' },
                    { label: 'High Pay Job in other sector' }
                  ],
                }],
              },
              {
                label: 'Payout Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Salary not received' },
                    { label: 'Fuel payment issue' },
                    { label: 'Delay in payouts' },
                  ],
                }],
              },
              {
                label: 'Already Working',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'On Leaves/Will Join in future' },
                    { label: 'Left for Personal Issue' },
                    { label: 'Out of station' },
                  ],
                }],
              },
              {
                label: 'Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Interested (Mention joining date)' },
                    { label: 'Not Joining due to distance issue' },
                  ],
                }],
              },
              {
                label: 'App/ Tech Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Login Issue' },
                    { label: 'Picup Location Issue' },
                  ],
                }],
              },
              {
                label: 'Bike Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'No Bike' },
                    { label: 'Repair & Service' },
                    { label: 'Insurance & Documentation' },
                  ],
                }],
              },
              {
                label: 'Not Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: "Won't do ECOM delivery job" },
                    { label: 'College Started' },
                    { label: 'Working only on Weekends' },
                    { label: 'Personal Issue' },
                    { label: 'Will not do Food Delivery' },
                    { label: 'Others' },
                  ],
                }],
              },
              {
                label: 'Opps Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Zone Change required' },
                    { label: 'ID Block' },
                    { label: 'Tagged to wrong Zone' },
                  ],
                }],
              },
              {
                label: 'Health Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Accident' },
                    { label: 'Fever/Covid' },
                    { label: 'Family Member unwell' },
                  ],
                }],
              },
              {
                label: 'Product',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Will Join in future' },
                    { label: 'Distance Issue' },
                    { label: 'MG not received' },
                    { label: 'Rate card issue' },
                    { label: 'Left due to low income' },
                    { label: 'OMT( Order Management Delay) Response Delay' },
                    { label: 'Less Oder received' },
                  ],
                }],
              },
              {
                label: 'Onboarding team',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'ID creation issue' },
                    { label: 'Delay in Onboarding' }
                  ],
                }],
              }
            ],
            }],
          },
          {
            label: 'Connected- Grivance call',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Payout Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Salary not received' },
                    { label: 'Fuel payment issue' },
                    { label: 'Delay in payouts' },
                  ],
                }],
              },
              {
                label: 'App/ Tech Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'KYC not getting verified' },
                    { label: 'Not able to upload pic' },
                  ],
                }],
              },
              {
                label: 'Opps Issue',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Zone Change required' },
                    { label: 'Location issue' },
                    { label: 'ID Block' },
                    { label: 'TL not responding' },
                  ],
                }],
              },
              {
                label: 'OBT',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'KYC not updated' },
                    { label: 'ID creation issue' },
                    { label: 'Tagged to wrong Zone' },
                  ],
                }],
              },
              {
                label: 'Product',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Distance Issue' },
                  { label: 'MG not received' },
                  { label: 'Rate card issue' },
                  { label: 'Left due to low income' },
                  { label: 'OMT( Order Management Delay) Response Delay' },
                  { label: 'Less Oder received' },
                  ],
                }],
              },
              {
                label: 'Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Interested (Mention joining date)' },
                    { label: 'Not Joining due to distance issue' },
                  ],
                }],
              },
              {
                label: 'Not Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: "Won't do ECOM delivery job" },
                    { label: 'College Started' },
                    { label: 'Working only on Weekends' },
                    { label: 'Personal Issue' },
                    { label: 'Will not do Food Delivery' },
                  ],
                }],
              },
            ],
            }],
          },
          {
            label: 'Connected- Leads Generation',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Interested (Onboarding form filled)' },
                    { label: 'Interested (Watting for Documents)' },
                    { label: 'Interested (Issue- Age/No bike/Distance issue)' },
                    { label: 'Interested (Not ready to share documents)' },
                  ],
                }],
              },
              {
                label: 'Not Interested',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Distance Issue/ Location issue' },
                    { label: 'Applied in error' },
                    { label: 'Not intrested in Delivery job' },
                    { label: 'Not intrested as Less payout' },
                    { label: 'Not intrested as Working somewere else' },
                  ],
                }],
              },
              {
                label: 'Will Join in future',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'No Bike' },
                    { label: 'Health Issues' },
                    { label: 'Working somewere else' },
                    { label: 'Confirm  and will come back to inform' },
                  ],
                }],
              },
            ],
            }],
          },
          {
            label: 'Connected- Adoc work',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Frazoo',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Informed to mark attendance' },
                    { label: 'Night Shift' },
                    { label: 'Week Off' },
                    { label: 'Call connected' },
                  ],
                }],
              },{
                label: 'Extra calling Request',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Call connected' },
                  ],
                }],
              }
            ],
            }],
          },
          {
            label: 'Connected- Tranning',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Traning',
                dependentQuestion: [ {
                  questionCode: 'SubCategory2',
                  question: 'SubCategory2',
                  questionName: 'QA_5',
                  option: [
                    { label: 'Joined Flipkart' },
                    { label: 'Kyc verification Completed' },
                    { label: 'KYC pending' },
                    { label: 'Traning pending' },
                    { label: 'Traning Completed' },
                  ],
                }],
              }],
            }],
          },
        ],
        }],
      },
      {
        label: 'Not Connected',
        dependentQuestion: [{
          questionCode: 'Campiagn',
          question: 'Campiagn',
          questionName: 'QA_3',
          option: [ {
            label: 'Not connected- Retention call',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'DND( do not disturb)',
              },
            ],
            }],
          },
          {
            label: 'Not connected- Grivance call',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Phone number not in use',
              },
            ],
            }],
          },
          {
            label: 'Not connected- Leads Generation',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Out of Network coverage area',
              },
            ],
            }],
          },
          {
            label: 'Not Connected- Adoc work',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Call Barred',
              }],
            }],
          },
          {
            label: 'Not Connected- Tranning',
            dependentQuestion: [ {
              questionCode: 'SubCateogry',
              question: 'SubCateogry',
              questionName: 'QA_4',
              option: [ {
                label: 'Call Disconnected',
                label: 'Switch Off',
                label: 'Talk Later/Call Back',
                label: 'Busy',
                label: 'RNR',
                label: 'Language barrier',
                label: 'Incorrect number',
                label: 'Left Msg',
                label: 'Dead air/ No responce from Caller',
              }],
            }],
          },
        ],
        }],
      },

// ===================


      ]
    },
    {
      questionCode: 'agentRemarks',
      question: 'Comments',
      questionName: 'QA_1108',
      questionType: 'textarea',
      additionalConfig: {
        rows: 4
      },
      option: [{}]
    }
  ];
  return questionArr;
}



