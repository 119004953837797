import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';


const AdminDashboardL2 = () => {
  return (
    <div style={{padding: 20}}>
      <Typography>OPS Admin Dashbaord</Typography>
    </div>
  );
};

export default AdminDashboardL2;
