import React, { useState } from 'react';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { Typography } from '@material-ui/core';
import packageConfig from '../../../package.json';


export default function RNRNumbersCount() {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const [formValues, setFormValues] = useState({
    startDate: '',
    endDate: ''
  });

  const onChangeFormValues = e => {
    let { name, value } = e.target;
    setFormValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  function clickFetchRecs(e) {
    setLoading(true);
    e.preventDefault();
    let url = `${packageConfig.proxyapi}/autodialeragentservice/api/RNRCalls`
    fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate: formValues.startDate,
          endDate: formValues.endDate,
          agent_role: localStorage.getItem('permissions') === 'canViewAreaManagerDashboard' ? 'agent_L2' : 'agent_L1'
        })
      })
        .then(res => res.json())
        .then(
          async result => {
            if (result.data.length > 0) {
              let i = 0;
              result.data.map(ele => {
                i = i + 1;
                return (ele.id = i);
              });
              // result.data.map((ele)=>{
              //   return
              // })
            }
            console.log(result.data);
            await setItems(result.data);
            setLoading(false);
            setShowTable(true);
          },
          error => {
            alert(error)
          }
        );
  }
  const profilesColumns = [
    {
      headerName: 'Customer Number',
      field: 'Destiantion',
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      headerName: 'Campaign Name',
      field: 'Campaign Name',
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      headerName: 'Campaign Count',
      field: 'Campaign Count',
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    },
    {
      headerName: 'Total Count',
      field: 'Total Count',
      flex: 1,
      align: 'center',
      headerAlign: 'center'
    }
  ];
  return (
    <div style={{ marginTop: 30, marginBottom: 30 }}>
      <Typography variant='h4' align='center'>
        RNR 
      </Typography>
      <form
        onSubmit={e => clickFetchRecs(e)}
        style={{
          width: '100%',
          border: '1px dotted black',
          padding: 30,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }}
      >
        <div>
          <input
            style={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 35,
              maxHeight: 50,
              border: '2px solid black'
            }}
            required
            value={formValues.startDate}
            name="startDate"
            onChange={onChangeFormValues}
            type="date"
          />
        </div>
        <div>
          <input
            style={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 35,
              maxHeight: 50,
              border: '2px solid black'
            }}
            required
            value={formValues.endDate}
            name="endDate"
            onChange={onChangeFormValues}
            type="date"
          />
        </div>
        <div>
          <input
            style={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 35,
              maxHeight: 50,

              border: '2px dashed black',
              backgroundColor: '#8bc34a',
              cursor: 'pointer'
            }}
            type="submit"
          />{' '}
        </div>
      </form>
      {showTable ? (<>
        <DataGrid
        rows={items}
        columns={profilesColumns}
        pageSize={20}
        // rowsPerPageOptions={[10, 20, 50]}
        components={{Toolbar: GridToolbar}}
        autoHeight="true"
        pagination
      />
      </>): (
        <>
        {loading && (<Typography variant='h4' align='center'>
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </Typography>)}
        </>
      )}

    </div>
  );
}
