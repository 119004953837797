import React, { useEffect, useState } from 'react';
import {Grid, Button, Box, Typography, Tab, Tabs, AppBar} from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import packageConfig from '../../../package.json';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

// DownloadReport

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1, backgroundColor: theme.palette.background.paper }
}));
const InteractionReport = props => {
  const classes = useStyles();
  //   const [campaigns, setCampaigns] = useState([]);
  //   const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [agentStausReports, setAgentStausReports] = useState([]);
  const [downagentStausReports, downsetAgentStausReports] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setendDate] = useState('');
  const [showTable, setShowTable] = useState(false);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

function downgetCallReport() {

    fetch(
      `${packageConfig.proxyapi}/autodialeragentservice/api/getagentPreformanceStatusAdmin`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate: startDate,
          endDate: endDate,
          agent_role: localStorage.getItem('permissions') === 'canViewAreaManagerDashboard' ? 'agent_L2' : 'agent_L1'

        })
      }
    )
     .then(res => res.json())
     .then(async response => {
            console.log(response);
            let agentArray = [];
            if (response.msg === 'succcessful') {
                
                for(let i = 0; i < response.data.length; i++){
                    let currentObject = response.data[i];
                    let arrayOfCurrentObject = Object.keys(currentObject);
                    let agentObject = {};
                    agentObject['id']=i;
                    arrayOfCurrentObject.forEach(e => {
                        if(typeof currentObject[e] === 'object'){
                            let objectArray = Object.keys(currentObject[e]);
                            objectArray.forEach(e_objectArray => {
                                agentObject[e_objectArray] = currentObject[e][e_objectArray]
                            })
                        }else{
                            agentObject[e] = currentObject[e];
                        }
                    })
                    agentArray.push(agentObject);
                }
                console.log('agentArray::',agentArray)
                downsetAgentStausReports(agentArray);
                setShowTable(true)
            }
        },
        error => {
          alert(error);
        }
      );
    // .then(error)
}
function getCallReport() {
    //   setShowLoading(true);
    let padate = new Date().toJSON().slice(0,10);
    fetch(
      `${packageConfig.proxyapi}/autodialeragentservice/api/agentPreformanceStatusAdmin`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          startDate: padate,
          endDate: padate,
          agent_role: localStorage.getItem('permissions') === 'canViewAreaManagerDashboard' ? 'agent_L2' : 'agent_L1'
        })
      }
    )
      .then(res => res.json())
      .then(async response => {
            // console.log(response);
            let reportArray = [];
            if (response.msg === 'succcessful') {
                if (response.performanceArray.length > 0) {
                    // console.log(response.performanceArray);
                    let i = 0;
                    response.performanceArray.map(ele => {
                        // console.log(ele);
                        i = i + 1;
                        ele.id = i;
                        reportArray.push(ele);
                    });
                }
                setAgentStausReports(reportArray);
                setShowTable(true)
            }
        },
        error => {
          alert(error);
        }
      );
    // .then(error)
}

useEffect(() => {
    const interval = setInterval(async () => {
        if(window.location.href.includes('liveAgents')){
            getCallReport()
        }
    }, 3000);
    console.log(interval)
}, [])


  const agentStatusColumns = [
    {
      headerName: 'Agent Name',
      field: 'agentName',
      flex: 1.0
    },
    {
      headerName: 'Login Time',
      field: 'Login Time',
      flex: 1.0
    },
    {
      headerName: 'Total Calls',
      field: 'Total Calls',
      flex: 1.0
    },
    {
      headerName: 'Connected Calls',
      field: 'Total Connected Calls',
      flex: 1.0
    },
    {
      headerName: 'Not Connected Calls',
      field: 'Total Not Connected Calls',
      flex: 1.0
    },
    {
      headerName: 'Average Hold Time',
      field: 'Average Hold Time',
      flex: 1.0
    },
    {
      headerName: 'Idle Time',
      field: 'Idle Time',
      flex: 1.0
    },
    {
      headerName: 'Break',
      field: 'Break',
      flex: 1.0
    },
    {
      headerName: 'SLA',
      field: 'SLA',
      flex: 1.0
    },
    {
      headerName: 'Productivity Time',
      field: 'Productivity Time',
      flex: 1.0
    },
    {
      headerName: 'Wrap Up Time',
      field: 'Wrap Up Time',
      flex: 1.0
    },
  ];
  const downagentStatusColumns = [
    {
      headerName: 'Date',
      field: 'createdAt',
      flex: 1.0,
      renderCell: rowData => (
        <>
        {rowData.row.createdAt}
        </>
      )
    },
    {
      headerName: 'agentName',
      field: 'agentName',
      flex: 1.0
    },
    {
      headerName: 'Login Time',
      field: 'Login Time',
      flex: 1.0
    },
    {
      headerName: 'Total Calls',
      field: 'Total Calls',
      flex: 1.0
    },
    {
      headerName: 'Connected Calls',
      field: 'Total Connected Calls',
      flex: 1.0
    },
    {
      headerName: 'Not Connected Calls',
      field: 'Total Not Connected Calls',
      flex: 1.0
    },
    {
      headerName: 'Average Hold Time',
      field: 'Average Hold Time',
      flex: 1.0
    },
    {
      headerName: 'Idle Time',
      field: 'Idle Time',
      flex: 1.0
    },
    {
      headerName: 'Break',
      field: 'Break',
      flex: 1.0
    },
    {
      headerName: 'SLA',
      field: 'SLA',
      flex: 1.0
    },
    {
      headerName: 'Productivity Time',
      field: 'Productivity Time',
      flex: 1.0
    },
    {
      headerName: 'Wrap Up Time',
      field: 'Wrap Up Time',
      flex: 1.0
    },
  ];
  const handleFormSubmitDateWiseAgent = e => {
    e.preventDefault();
    downgetCallReport();
  };

  return (
    <div style={{ marginTop: 40 }}>
      <Grid container spacing={3} direction="row">
        {showTable && (
          <Grid item xs={12} sm={12} lg={12} style={{ display: 'flex', justifyContent: 'left', padding: '5px 30px'}} >
            <div className={classes.root}>
              <AppBar position="static" style={{backgroundColor: "#0070df9e"}}>
              {/* #212121 */}
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  <Tab label="Agent Performance" {...a11yProps(0)} />
                  <Tab label="Agent Performance Download" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <Grid item xs={12} sm={12} lg={12}>
                  <DataGrid rows={agentStausReports} columns={agentStatusColumns} 
                    pageSize={10}autoHeight="true"pagination/>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid item xs={12} sm={12} lg={12}>
                    <Grid item xs={12} sm={12} lg={12} style={{ textAlign: 'center', padding: '20px 30px'}}>
                        <form onSubmit={handleFormSubmitDateWiseAgent}>
                            <label>Select Date ::
                            <input required type="date" value={startDate} onChange={e => setStartDate(e.target.value)} style={{ padding: 5 }}/>
                            </label>
                            <label>End Date ::
                            <input required type="date" value={endDate} onChange={e => setendDate(e.target.value)} style={{ padding: 5 }}/>
                            </label>
                            <input
                            type="submit" title="Submit" style={{ padding: '6px 60px', margin: 5, cursor: 'pointer' }}/>
                        </form>
                    </Grid>
                  <DataGrid rows={downagentStausReports} columns={downagentStatusColumns} components={{Toolbar: GridToolbar}}
                    pageSize={10}autoHeight="true"pagination/>
                </Grid>
              </TabPanel>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default InteractionReport;

