import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DataGrid } from '@material-ui/data-grid';
import Dialog from './dialog';

import {
  Grid,
  Typography,
  Card,
  CardContent,
  Tooltip,
  IconButton
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import moment from 'moment';

import Dialog1 from './updatedialog';
import Popup from './PopUp';
import packageConfig from '../../../package.json';

import { AGENT_SERVICE } from 'src/modules/dashboard-360/utils/endpoints';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

const Inbound = props => {
  const [agents, setAgents] = useState([]);
  const [liveCalls, setLivecalls] = useState([]);
  const [breakdetails, setBreakdetails] = useState([]);
  const [agentsFree, setAgentsFree] = useState([]);
  const [idle, setIdle] = useState([]);
  const [update, setUpdate] = useState({});
  const [show, setShow] = useState(false);
  const [ManageAgent, setManageAgent] = useState([]);
  const [loggedinAgents, setLoggedInAgents] = useState([]);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  let [agentAddObject, setAgentAddObject] = useState({
    email: '',
    phone: '',
    name: '',
    agent_type: ''
  });
  let [agentAddObjectAgentRole, setAgentAddObjectAgentRole] = useState('')

  const hadleAddAgent = e => {
    let { name, value } = e.target;
    setAgentAddObject(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const profilesColumns = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 0.5
    },
    {
      headerName: 'SIP ID',
      field: 'Location',

      renderCell: rowData => (
        <>
          <Tooltip title="update">
            <IconButton onClick={() => <Dialog1 update={rowData.row} />}>
              <Typography>{rowData.row.Location}</Typography>
            </IconButton>
          </Tooltip>
        </>
      ),
      flex: 0.5
    },

    {
      headerName: 'Email',
      field: 'UserName',
      flex: 0.5
    },
    {
      headerName: 'Queue',
      field: 'Queue',
      flex: 0.5
    }
  ];
  const agentStatusColumn = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Status',
      field: 'Event',
      flex: 1.5
    },
    {
      headerName: 'Duration',
      field: 'Mduration',
      flex: 1
    }
  ];
  const liveCallsColumn = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Customer Number',
      field: 'CallerIDNum',
      flex: 1
    },
    {
      headerName: 'Queue',
      field: 'Queue',
      flex: 1
    },
    {
      headerName: 'Duration',
      field: 'Mduration',
      flex: 1
    }
  ];
  const callsNotDisposed = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Customer Number',
      field: 'CallerIDNum',
      flex: 1
    },
    {
      headerName: 'Queue Name',
      field: 'Queue',
      flex: 1
    },
    {
      headerName: 'Duration',
      field: 'Mduration',
      flex: 1
    }
  ];
  const agentsFreeColumn = [
    {
      headerName: 'Name',
      field: 'name',
      flex: 1
    },
    {
      headerName: 'Customer Number',
      field: 'CallerIDNum',
      flex: 1
    },
    {
      headerName: 'Queue ',
      field: 'Queue',
      flex: 1
    },
    {
      headerName: 'Duration',
      field: 'Mduration',
      flex: 1
    }
  ];
  const showProfile = data => {
    console.log(data.row);
    // const record = profiles1.filter((ele) => {
    //     return ele._id === data.row._id
    // })
    // console.log(record)

    var value = JSON.stringify(data.row);
    localStorage.setItem('Formdata', value);
    setUpdate(data.row);
    setShow(true);
  };

  // const handleRejectPopup = (id) => {
  //     // console.log(id)

  //     // setOpen(true)
  // }

  const getAgents = async () => {
    let typeAgent 
    if(localStorage.getItem('permissions') === 'canViewAreaManagerDashboard'){
      typeAgent = 'agent_L2'
    }else{
      typeAgent = 'agent_L1'
    }
    axios
      .get(`/autodialeragentservice/api/agents?agent_role=${typeAgent}`)
      .then(res => {
        console.log(res.data);
        let i = 0;
        if (res.data.length > 0) {
          res.data.map(ele => {
            i = i + 1;
            ele.id = i;
            if (ele.Event === 'AgentComplete') {
              return (ele.Event = 'Call Disconnected yet to be Disposed');
            } else if (ele.Event === 'LoggedOut') {
              return (ele.Event = 'Logged Out');
            } else if (ele.Event === 'BREAKIN') {
              return (ele.Event = 'On Break');
            } else if (ele.Event === 'LoggedIn') {
              return (ele.Event = 'Logged In');
            } else if (ele.Event === 'BREAKOUT') {
              return (ele.Event = 'Free for Next Call');
            } else if (ele.Event === 'AgentCalled') {
              return (ele.Event = 'Ringing');
            } else if (ele.Event === 'AgentRingNoAnswer') {
              return (ele.Event = 'Missed Last Call, Ready for Next Call');
            } else if (ele.Event === 'AgentConnect') {
              return (ele.Event = 'On Call');
            } else if (ele.Event === 'AgentDisposed') {
              return (ele.Event = 'Free for Next Call');
            }
            return ele.Event;
          });

          let value;
          res.data.map(ele => {
            value = new Date(ele.updatedAt).toUTCString();
            //console.log(value)
            // value = new date() - new Date(value)
            value = new Date() - new Date(value);
            console.log(value);
            value = new Date(value).toUTCString().slice(17, 25);
            //value = moment(value).format().slice(11, 18)
            //value = moment(value).format()
            return (ele.Mduration = value);
          });
          console.log(res.data, 'siiiiiiiiiiiiiiiiiiiiiiiiii');
          // const res = await Axios.get(`/autodialeragentservice/api/agents?agent_role=${typeAgent}`);
          setAgents(res.data);
          const magagent = res.data.filter(ele => {
            var formattted1 = parseInt(ele.updatedAt);
            var formatte = moment.unix(formattted1 / 1000).format('DD-MM-yyyy');
            var currentdate = moment(new Date()).format('DD-MM-yyyy');

            if (currentdate === formatte) {
              // Date equals today's date
              console.log('sub', formatte);
              return ele;
            }
            return ele;
          });
          setManageAgent(magagent);
          const idle1 = res.data.filter(ele => {
            return ele.Event === 'Call Disconnected Not Disposed';
          });

          setIdle(idle1);
          const loggedIN = res.data.filter(ele => {
            return ele.Event != 'Logged Out';
          });
          setLoggedInAgents(loggedIN);
          const break1 = res.data.filter(ele => {
            return ele.Event === 'On Break';
          });
          setBreakdetails(break1);
          const agentsFree1 = res.data.filter(ele => {
            return ele.Event === 'Free for Next Call';
          });
          setAgentsFree(agentsFree1);
          const live = res.data.filter(ele => {
            return ele.Event === 'On Call' || ele.Event === 'CustomerAnswered';
          });
          setLivecalls(live);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      if (window.location.href.includes('manageAgents')) {
        getAgents();
      }
    }, 2000);
    console.log(interval);
  }, []);

  console.log(agents, 'agents');

  const handleClose = () => {
    setShow(false);
    setUpdate({});
  };

  let inputFormDetails = [
    {
      type: 'text',
      placeholder: 'Enter Email',
      name: 'email',
      value: agentAddObject.email
    },
    {
      type: 'text',
      placeholder: 'Enter phone',
      name: 'phone',
      value: agentAddObject.phone
    },
    {
      type: 'text',
      placeholder: 'Enter name',
      name: 'name',
      value: agentAddObject.name
    }
  ];
  const formSubmitAddAgent = (e) => {
    e.preventDefault();
      fetch(
        `${packageConfig.proxyapi}/payfiller/user/register/agent`, 
        //   `http://localhost:6014/payfiller/user/register/agent`, 
      {method: "post",         
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "email": agentAddObject.email,
        "password": agentAddObject.phone,
        "phone": agentAddObject.phone,
        "sip_id": `Local/9${agentAddObject.phone}@from-internal`,
        "name": agentAddObject.name,
        "role": "DT" + agentAddObjectAgentRole,
        "agent_type": agentAddObjectAgentRole,
        "addAgent": true,
        "agent_role": "agent_" + agentAddObjectAgentRole
    })})
    .then(response => {
        alert("Success");
    }, err => {
        alert(err);
    })
  }


  return (
    <>
      <div className={classes.root}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Agents Edit" {...a11yProps(0)} />
            <Tab label="Add Agent" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Grid container spacing={3} direction="row">
            <Grid item xs={12} sm={12} lg={12}>
              <Card>
                <CardContent style={{ height: 'auto' }}>
                  <Grid container spacing={3} direction="row">
                    <Grid item xs={12} sm={12} lg={5}></Grid>
                    <Grid item xs={12} sm={12} lg={5}>
                      {' '}
                      <b>Manage Agents</b>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={1}></Grid>
                    <Grid item xs={12} sm={12} lg={1}>
                      {/* <Dialog /> */}
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                      <DataGrid
                        rows={agents}
                        columns={profilesColumns}
                        pageSize={100}
                        // rowsPerPageOptions={[10, 20, 50]}
                        onRowClick={showProfile}
                        autoHeight="true"
                        pagination
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="h5">Add agent</Typography>
            </Grid>
            {/* <Grid item xs={12} sm={12} lg={6}>
            <Card style={{ 'height': 'auto' }}>
                <CardContent>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={12} sm={12} lg={5}></Grid>
                        <Grid item xs={12} sm={12} lg={5}> <b>Agents Status</b></Grid>
                        <Grid item xs={12} sm={12} lg={1}></Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <DataGrid rows={loggedinAgents} columns={agentStatusColumn} pageSize={5}
                                //rowsPerPageOptions={[5, 20, 50]}
                                autoHeight="true"
                                pagination />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
            <Card style={{ 'height': 'auto' }}>
                <CardContent>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={12} sm={12} lg={5}></Grid>
                        <Grid item xs={12} sm={12} lg={5}> <b>Break Details</b></Grid>
                        <Grid item xs={12} sm={12} lg={1}></Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <DataGrid rows={breakdetails} columns={agentStatusColumn} pageSize={5}
                                //rowsPerPageOptions={[5, 20, 50]}
                                autoHeight="true"
                                pagination />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
            <Card style={{ 'height': 'auto' }}>
                <CardContent>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={12} sm={12} lg={5}></Grid>
                        <Grid item xs={12} sm={12} lg={5}> <b>Live Calls</b></Grid>
                        <Grid item xs={12} sm={12} lg={1}></Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <DataGrid rows={liveCalls} columns={liveCallsColumn} pageSize={5}
                            // <DataGrid rows={liveCalls} columns={liveCallsColumn} pageSize={5}
                                //rowsPerPageOptions={[5, 20, 50]}
                                autoHeight="true"
                                pagination />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
            <Card style={{ 'height': 'auto' }}>
                <CardContent>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={12} sm={12} lg={5}></Grid>
                        <Grid item xs={12} sm={12} lg={5}> <b>Agents Free</b></Grid>
                        <Grid item xs={12} sm={12} lg={1}></Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <DataGrid rows={agentsFree} columns={agentsFreeColumn} pageSize={5}
                                //rowsPerPageOptions={[5, 20, 50]}
                                autoHeight="true"
                                pagination />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} sm={12} lg={6}>
            <Card style={{ 'height': 'auto' }}>
                <CardContent>
                    <Grid container spacing={3} direction="row">
                        <Grid item xs={12} sm={12} lg={5}></Grid>
                        <Grid item xs={12} sm={12} lg={5}> <b>Call Completed yet to be Disposed</b></Grid>
                        <Grid item xs={12} sm={12} lg={1}></Grid>
                        <Grid item xs={12} sm={12} lg={12}>
                            <DataGrid rows={idle} columns={callsNotDisposed} pageSize={5}
                                //rowsPerPageOptions={[5, 20, 50]}
                                autoHeight="true"
                                pagination />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid> */}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          Add Agent
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <form
                onSubmit={
                  formSubmitAddAgent
                }
              >
                <Grid container>
                <Grid item xs={12} md={3} lg={2}>
                  {agentAddObjectAgentRole}
                  <select style={{margin:5, padding: 10}}  required value={agentAddObjectAgentRole} onChange={(e) => setAgentAddObjectAgentRole(e.target.value)}>
                    <option value=''>{'<--'}select value{'-->'}</option>
                    <option value='L1'>Type 1 (default agent)</option>
                    <option value='L2'>Type 2 (group 2 agent)</option>
                  </select>
                    </Grid>
                  {inputFormDetails.map(data => (
                    <Grid item xs={12} md={4} lg={3}>
                      <input
                        type="text"
                        required
                        placeholder={data.placeholder}
                        name={data.name}
                        value={data.value}
                        onChange={hadleAddAgent}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12} md={4} lg={3}>
                    <input type="submit" />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </TabPanel>
      </div>

      <Popup record={update} show={show} handleClose={handleClose} />
    </>
  );
};

export default Inbound;
